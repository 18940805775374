import React, { useRef } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import PageBottom from '../components/PageBottom'
import { Helmet } from 'react-helmet'
import { useState } from "react";
import ReactGA from "react-ga4"
import { useEffect } from 'react'

const ContactUs = () => {
    const scriptUrl = "https://script.google.com/macros/s/AKfycby_g3f2gTMTafYX7u8E_xZwFxGBFjIqxDbpnpT9XW8DMR_z2qpXYvHu2G5ocObjkWc4iQ/exec"
    const formRef = useRef(null)
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({})
    const [showLoader, setShowLoader] = useState(false);

    const handleInputChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value })
    }

    const handleSubmit = (e) => {
        setShowLoader(true);

        e.preventDefault()
        const hiddenInput = document.createElement('input');
        hiddenInput.type = 'hidden';
        hiddenInput.name = 'Date';
        hiddenInput.value = new Date();
        formRef.current.appendChild(hiddenInput);
        fetch(scriptUrl, {
            method: 'POST',
            body: new FormData(formRef.current),


        }).then(res => {
            setShowLoader(false);
            setShowModal(true);
            console.log("SUCCESSFULLY SUBMITTED")

        })
            .catch(err => console.log(err))
    }

    const reloadPage = () => {
        window.location.reload(false);
    }

    ReactGA.initialize("G-RQ96KPQZ6W");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/contact", title: "Contact Us" });

    useEffect(() => {
        document.title = 'Contact Us | Cameron Immigration & Legal Services';
      }, []);

    return (
        <div>
            <Helmet>
                <title>Contact Us | Cameron Immigration & Legal Services</title>
                <meta content="width=device-width, initial-scale=1.0" name="viewport" />
                <meta property="og:title" content="Contact Us | Cameron Immigration & Legal Services" />
                <meta property="og:site_name" content="Cameron Immigration & Legal Services" />
                <meta property="og:url" content="https://www.cameronimmigration.com/" />
                <meta property="og:description" content="There are 100’s of options for visiting, migrating and remaining in Canada. As Canadian immigration law continues to adjust its rules and regulations, its important to have up to date knowledge. Each matter is unique and must be looked at from its own perspective, allowing us to thoroughly evaluate each matter and seek the best possible route in immigrating or remaining in Canada." />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://www.cameronimmigration.com/static/media/thumbnail-img.3746441c010fec9f33b6.png"></meta>
                <meta content="There are 100’s of options for visiting, migrating and remaining in Canada. As Canadian immigration law continues to adjust its rules and regulations, its important to have up to date knowledge. Each matter is unique and must be looked at from its own perspective, allowing us to thoroughly evaluate each matter and seek the best possible route in immigrating or remaining in Canada." />
                <meta property="og:keywords" content="Leagl Services, Canada Visa, Work Visa canada, Study Visa canada, Visitor Visa canada, Super Visa canada, Family Sponsorship canada, Humanitarian & Compassionate, Express Entry canada, PNP canada, LMIA canada, Business Visitors canada, Seasonal Workers canada" />
                <meta name="keywords" content="Leagl Services, Canada Visa, Work Visa canada, Study Visa canada, Visitor Visa canada, Super Visa canada, Family Sponsorship canada, Humanitarian & Compassionate, Express Entry canada, PNP canada, LMIA canada, Business Visitors canada, Seasonal Workers canada" />
                <meta content="Cameron Immigration & Legal Services" name="author" />
                <meta name="title" content="Contact Us | Cameron Immigration & Legal Services" />
                <link rel="canonical" href="https://www.cameronimmigration.com/" />
                <meta http-equiv='cache-control' content='no-cache' />
                <meta http-equiv='expires' content='0' />
                <meta http-equiv='pragma' content='no-cache' />
                <link rel="icon" href="/cameron-logo.jpg" type="image/png" sizes="16x16" />

             
            </Helmet>
            <Header></Header>
            <div className='contact-us-page'>
                <div className='page-banner'>
                    <div className='container'>
                        <div className='right-sec'>
                            <div className='sec-title'>
                                <span>Contact Us</span>
                            </div>
                            <div className='sec-title2'>
                                <h2>Start the Process today!</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='contact-form'>
                    <div className='container'>
                        <div className='row contact-card'>
                            <div className='col-12 col-sm-12 col-md-7 col-xl-7 col-xxl-7 form-inner'>
                                <h2>We are here to assist you</h2>
                                <p>Send us a message and let's start the conversation! Your inquiries matter, and we're here to assist you promptly.</p>
                                <form method='POST' ref={formRef} onSubmit={handleSubmit}>
                                    <div className='row'>
                                        <div className='col-12 col-sm-6 col-md-6 col-xl-6 col-xxl-6'>
                                            <input type="text" name="Name" class="form-control" placeholder='Full Name' onChange={handleInputChange} required />
                                        </div>
                                        <div className='col-12 col-sm-6 col-md-6 col-xl-6 col-xxl-6'>
                                            <input type="text" name="Contact" class="form-control" placeholder='Contact Number' onChange={handleInputChange} required />
                                        </div>
                                        <div className='col-12 col-sm-12 col-md-12 col-xl-6 col-xxl-6'>
                                            <input type="email" name="Email" class="form-control" placeholder='Email ID' onChange={handleInputChange} required />
                                        </div>
                                        <div className='col-12 col-sm-12 col-md-12 col-xl-6 col-xxl-6'>
                                            <input type="text" name="Country" class="form-control" placeholder='Country' onChange={handleInputChange} required />
                                        </div>
                                        <div className='col-12 col-sm-12 col-md-12 col-xl-12 col-xxl-12'>
                                            <select class="form-select" name="Service" onChange={handleInputChange} required>
                                                <option selected>Select Visa Service</option>
                                                <option value="Work Visa" >Work Visa</option>
                                                <option value="Study Visa" >Study Visa</option>
                                                <option value="Visitor Visa" >Visitor Visa</option>
                                                <option value="Super Visa" >Super Visa</option>
                                                <option value="Family Sponsorship" >Family Sponsorship</option>
                                                <option value="Humanitarian & Compassionate" >Humanitarian & Compassionate</option>
                                                <option value="Express Entry" >Express Entry</option>
                                                <option value="Provincial Nominee Program (PNP)" >Provincial Nominee Program (PNP)</option>
                                                <option value="Labour Market Impact Assessment (LMIA)" >Labour Market Impact Assessment (LMIA)</option>
                                                <option value="Business Visitors" >Business Visitors</option>
                                                <option value="Seasonal Workers" >Seasonal Workers</option>
                                            </select>
                                        </div>
                                        <div className='col-12 col-sm-12 col-md-12 col-xl-12 col-xxl-12 '>
                                            <textarea class="form-control" placeholder="Tell us more about your needs..." name="Message" id="brif-msg" onChange={handleInputChange} required></textarea>
                                        </div>
                                    </div>
                                    <button type='submit'>Submit <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 23 18" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9C0 8.28992 0.587626 7.71428 1.3125 7.71428L16.5188 7.71428L10.8844 2.19485C10.3719 1.69275 10.3719 0.878677 10.8844 0.376575C11.397 -0.125528 12.228 -0.125528 12.7406 0.376575L20.6156 8.09086C21.1281 8.59296 21.1281 9.40703 20.6156 9.90914L12.7406 17.6234C12.228 18.1255 11.397 18.1255 10.8844 17.6234C10.3719 17.1213 10.3719 16.3072 10.8844 15.8051L16.5188 10.2857H1.3125C0.587626 10.2857 0 9.71008 0 9Z" fill="#192451" />
                                    </svg>
                                    </button>
                                </form>
                            </div>
                            <div className='col-12 col-sm-12 col-md-5 col-xl-5 col-xxl-5 p-0'>
                                <iframe title='Contact form' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11521.090577266836!2d-79.5518139!3d43.7879553!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b2f0bcb06a587%3A0xe3d578b3007b59b3!2sCameron%20Immigration%20%26%20Legal%20Services!5e0!3m2!1sen!2sca!4v1702539249549!5m2!1sen!2sca" width="100%" height="auto" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="contact-data">
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 col-sm-12 col-md-4 col-xl-4 col-xxl-4'>
                                <div className='counters-div'>
                                    <div className='counters-icon'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.94496 0.798556C4.10451 -0.360998 6.01796 -0.241871 7.02474 1.05255L9.8274 4.65598C10.3417 5.31725 10.5235 6.17826 10.3203 6.99098L9.46518 10.4114C9.37491 10.7725 9.48071 11.1545 9.74391 11.4177L13.5823 15.2561C13.8455 15.5193 14.2275 15.6251 14.5886 15.5348L18.009 14.6797C18.8217 14.4765 19.6828 14.6583 20.344 15.1726L23.9474 17.9753C25.2419 18.982 25.361 20.8955 24.2014 22.055L22.5859 23.6706C21.4301 24.8264 19.7014 25.3339 18.0902 24.7674C14.0899 23.361 10.335 21.0584 7.13826 17.8617C3.94156 14.665 1.63898 10.9101 0.232559 6.90976C-0.333879 5.29863 0.173641 3.56987 1.32945 2.41406L2.94496 0.798556Z" fill="#192451" />
                                        </svg>
                                    </div>
                                    <div className='conunters-nos'>

                                        <a href="tel:+1-416-889-9178">+1-416-889-9178</a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-sm-12 col-md-4 col-xl-4 col-xxl-4'>
                                <div className='counters-div'>
                                    <div className='counters-icon'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                            <path d="M0.0774772 5.55538C0.393328 4.16381 1.63785 3.125 3.125 3.125H21.875C23.3622 3.125 24.6067 4.16381 24.9225 5.55538L12.5 13.1469L0.0774772 5.55538ZM0 7.33919V18.4384L9.06749 12.8804L0 7.33919ZM10.5647 13.7954L0.299798 20.0873C0.800944 21.1442 1.87762 21.875 3.125 21.875H21.875C23.1224 21.875 24.1991 21.1442 24.7002 20.0873L14.4353 13.7954L12.5 14.9781L10.5647 13.7954ZM15.9325 12.8804L25 18.4384V7.33919L15.9325 12.8804Z" fill="#192451" />
                                        </svg>
                                    </div>
                                    <div className='conunters-nos'>
                                        <a href="mailto:info@cameronimmigration.com">info@cameronimmigration.com</a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-sm-12 col-md-4 col-xl-4 col-xxl-4'>
                                <div className='counters-div justify-content-xl-end' >
                                    <div className='counters-icon'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                            <path d="M25 12.5C25 19.4036 19.4036 25 12.5 25C5.59644 25 0 19.4036 0 12.5C0 5.59644 5.59644 0 12.5 0C19.4036 0 25 5.59644 25 12.5ZM12.5 5.46875C12.5 5.03728 12.1502 4.6875 11.7188 4.6875C11.2873 4.6875 10.9375 5.03728 10.9375 5.46875V14.0625C10.9375 14.3429 11.0877 14.6017 11.3311 14.7408L16.7999 17.8658C17.1745 18.0799 17.6517 17.9497 17.8658 17.5751C18.0799 17.2005 17.9497 16.7233 17.5751 16.5092L12.5 13.6091V5.46875Z" fill="#192451" />
                                        </svg>
                                    </div>
                                    <div className='conunters-nos'>
                                        <p>Monday – Friday <br></br>10.00 am – 6.00 pm</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal fade  ${showModal ? 'show' : ''}`} tabIndex="-1" id="thank_you_modal" style={{ display: showModal ? 'block' : 'none' }}>
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">

                        <div class="modal-body">
                            <div className='tick-svg'>
                                <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="80" height="80" rx="40" fill="#02AF09" />
                                    <path d="M52.5707 29.6652C53.7212 28.5117 55.5866 28.5117 56.7371 29.6652C57.8735 30.8046 57.8874 32.6433 56.7789 33.7998L41.0972 53.4537C41.0745 53.4821 41.0503 53.5092 41.0247 53.5349C39.8742 54.6884 38.0089 54.6884 36.8584 53.5349L26.4629 43.112C25.3123 41.9584 25.3123 40.0881 26.4629 38.9346C27.6134 37.781 29.4787 37.781 30.6292 38.9346L38.8528 47.1798L52.4926 29.7536C52.5168 29.7226 52.5429 29.6931 52.5707 29.6652Z" fill="white" />
                                </svg>
                            </div>
                            <p>Thanks for getting in touch! We've received your message and appreciate you reaching out.</p>
                            <p>We're on it! Our team will review your message and get back to you soon. </p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn" onClick={reloadPage}>Ok</button>
                        </div>
                    </div>
                </div>
            </div>

            <PageBottom></PageBottom>
            <Footer></Footer>
            <div style={{ display: showLoader ? 'block' : 'none' }}>
                <div className="loader " id="showLoader" >
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="loading-text"><b>Please Wait...</b></div>
                </div>
            </div>


        </div>
    )
}

export default ContactUs;
