import React from 'react'
import { HashLink as Link } from 'react-router-hash-link'

export default function ScrollTop() {
  return (
    <div>
        <div class="scroll-up-btn">
            <Link smooth to="#top">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 31 31" fill="none">
                <path d="M14.9975 30.2634C13.8142 30.2423 12.87 29.3858 12.8885 28.3504L13.277 6.63054L3.93548 14.5139C3.08568 15.2311 1.72911 15.2068 0.905503 14.4597C0.0818925 13.7126 0.103123 12.5256 0.952923 11.8085L14.0092 0.79022C14.859 0.0730697 16.2156 0.097333 17.0392 0.844413L29.6931 12.3225C30.5167 13.0696 30.4955 14.2566 29.6457 14.9738C28.7959 15.6909 27.4393 15.6667 26.6157 14.9196L17.562 6.70718L17.1736 28.4271C17.155 29.4624 16.1808 30.2846 14.9975 30.2634Z" fill="#192451"/>
                </svg>
            </Link>
        </div>
    </div>
  )
}
