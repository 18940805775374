import React from 'react'

function pageBottom() {
  return (
    <div className='page-bottom'>
      <div className='container'>
          <div className='sec-content'>
            <h2>Thinking about moving to Canada?</h2>
            <p>Our team of skilled professionals is passionate about helping individuals and families overcome the complexities of the immigration process.</p>
            <h3><a href='#tel:+1-416-889-9178 '>Call Us: +1-416-889-9178 </a></h3>
          </div>
      </div>
    </div>
  )
}

export default pageBottom;