import React, { Component } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import PageBottom from '../components/PageBottom'
import { Helmet } from 'react-helmet'
import ReactGA from "react-ga4"
import { useEffect } from 'react'

export default function TermsAndPolicies(){


    ReactGA.initialize("G-RQ96KPQZ6W");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/terms-and-policies", title: "Terms And Policies" });

    useEffect(() => {
        document.title = 'Terms And Policies | Cameron Immigration & Legal Services';
      }, []);

    return (
      <div>
        <Helmet>
            <title>Terms And Policies | Cameron Immigration & Legal Services</title>
            <meta content="width=device-width, initial-scale=1.0" name="viewport" />
            <meta property="og:title" content="Terms And Policies | Cameron Immigration & Legal Services" />
            <meta property="og:site_name" content="Cameron Immigration & Legal Services"/>
            <meta property="og:url" content="https://www.cameronimmigration.com/"/>
            <meta property="og:description" content="There are 100’s of options for visiting, migrating and remaining in Canada. As Canadian immigration law continues to adjust its rules and regulations, its important to have up to date knowledge. Each matter is unique and must be looked at from its own perspective, allowing us to thoroughly evaluate each matter and seek the best possible route in immigrating or remaining in Canada."/>
            <meta property="og:type" content="website"/>
            <meta property="og:image" content="https://www.cameronimmigration.com/static/media/thumbnail-img.3746441c010fec9f33b6.png"></meta>
            <meta content="There are 100’s of options for visiting, migrating and remaining in Canada. As Canadian immigration law continues to adjust its rules and regulations, its important to have up to date knowledge. Each matter is unique and must be looked at from its own perspective, allowing us to thoroughly evaluate each matter and seek the best possible route in immigrating or remaining in Canada." />
            <meta property="og:keywords" content="Leagl Services, Canada Visa, Work Visa canada, Study Visa canada, Visitor Visa canada, Super Visa canada, Family Sponsorship canada, Humanitarian & Compassionate, Express Entry canada, PNP canada, LMIA canada, Business Visitors canada, Seasonal Workers canada"/>
            <meta name="keywords" content="Leagl Services, Canada Visa, Work Visa canada, Study Visa canada, Visitor Visa canada, Super Visa canada, Family Sponsorship canada, Humanitarian & Compassionate, Express Entry canada, PNP canada, LMIA canada, Business Visitors canada, Seasonal Workers canada"/>
            <meta content="Cameron Immigration & Legal Services" name="author" />
            <meta name="title" content="Terms And Policies | Cameron Immigration & Legal Services"/>
            <link rel="canonical" href="https://www.cameronimmigration.com/" />
            <meta http-equiv='cache-control' content='no-cache'/>
            <meta http-equiv='expires' content='0'/>
            <meta http-equiv='pragma' content='no-cache'/>
            <link rel="icon" href="/cameron-logo.jpg" type="image/png" sizes="16x16"/>

          
        </Helmet>
        <Header></Header>
        <div className='teams-policies-page'>
            <div className='page-banner'>
                <div className='container'>
                    <div className='right-sec'>
                        <div className='sec-title'>
                            <span>Terms And Policies</span>
                        </div>
                        <div className='sec-title2'>
                            <h2>Terms Of Use</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className='terms-data'>
                <div className='container'>
                    <div className='terms-content'>
                        <h2>Terms of Use</h2>
                        <p>
                            Our website <a href='https://www.cameronimmigration.com/'>https://www.cameronimmigration.com/</a> provides general information on Immigration matters and should not be relied upon as legal advice. If you require legal advice, you should retain qualified legal professionals. If you would like to retain Cameron Immigration & Legal Services. for legal advice, you can contact us by Email or Phone.
                        </p>
                    </div>
                    <div className='terms-content'>
                        <h2>Agreement</h2>
                        <ul>
                            <li>
                            Parties: This is an Agreement between you and Cameron Immigration & Legal Services.  
                            </li>
                            <li>For the purposes of this Agreement “You” includes any “Person” including natural person and any type of Corporation entity– whether incorporated or not.</li>
                        </ul>
                    </div>
                    <div className='terms-content'>
                        <h2>Purpose</h2>
                        <ul>
                            <li>
                            The Agreement governs the use of Cameron Immigration & Legal Services Website <a href='https://www.cameronimmigration.com/'>https://www.cameronimmigration.com/</a> and its content  
                            </li>
                            
                        </ul>
                    </div>
                    <div className='terms-content'>
                        <h2>Content</h2>
                        <ul>
                            <li>
                            The content of this Website is for general use and is subject to change without notice.
                            </li>
                            
                        </ul>
                    </div>
                    <div className='terms-content'>
                        <h2>No Lawyer-Client Relationship</h2>
                        <ul>
                            <li>
                            No lawyer-client, advisory, or fiduciary relationship is created by this Agreement or your use of the Website or your communication with Cameron Immigration & Legal Services through the Website.
                            </li>
                            
                        </ul>
                    </div>
                    <div className='terms-content'>
                        <h2>Acceptance</h2>
                        <ul>
                            <li>
                            Each time you use the website it signifies that you accept the terms of this Agreement and of any person you claim to represent. You are bound without any limitation or qualification.
                            </li>
                            
                        </ul>
                    </div>
                    <div className='terms-content'>
                        <h2>Legality</h2>
                        <ul>
                            <li>
                            You represent and warrant that you have the legal authority to agree to and accept this Agreement on behalf of yourself and any person you purport to represent.
                            </li>
                            
                        </ul>
                    </div>
                    <div className='terms-content'>
                        <h2>Liability and Acknowledgement</h2>
                        <ul>
                            <li>
                            Cameron Immigration & Legal Services does not warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose.
                            </li>
                            <li>You acknowledge that such information and materials may contain inaccuracies or errors or that the website and the server(s) that make it available are free from viruses or harmful components. And we expressly exclude liability for any such inaccuracies, errors, viruses or other harmful components to the fullest extent permitted by law.</li>
                            <li>
                            Your use of any information or materials on this website is entirely at your own risk, for which Cameron Immigration & Legal Services or any of its members shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements.
                            </li>
                            
                        </ul>
                    </div>
                    <div className='terms-content'>
                        <h2>Liability and Acknowledgement</h2>
                        <ul>
                            <li>
                            Cameron Immigration & Legal Services does not guarantee the confidentiality of any communications received by email, voicemail messages until the Retainer Agreement is signed by You and Cameron Immigration & Legal Services.
                            </li>
                            <li>Cameron Immigration & Legal Services does not guarantee the confidentiality of any communication through use of its website.</li>
                            <li>
                            Unsolicited information and material may not be treated as confidential and will not be protected by any solicitor-client privilege.
                            </li>
                            <li>
                            Although the use of the website may facilitate communications with members of Cameron Immigration & Legal Services by e-mail or voicemail, receipt of any such communications or transmissions by any member of Cameron Immigration & Legal Services does not create a solicitor-client relationship, unless our firm agrees to represent you.
                            </li>
                            
                        </ul>
                    </div>
                    <div className='terms-content'>
                        <h2>Liability and Acknowledgement</h2>
                        <ul>
                            <li>
                            The website of Cameron Immigration & Legal Services has been designed to provide information on Immigration related matters that might be of interest to current or potential clients. For the purpose there are links to the third-party sites through-out the website of Cameron Immigration & Legal Services. The links are provided for the sake of convenience only. Cameron Immigration & Legal Services. does not endorse or recommend the information contained in linked web sites, or guarantees its accuracy, timeliness or fitness for a particular purpose. Cameron Immigration & Legal Services takes no responsibility for the content or practices of third-party sites.
                            </li>
                        </ul>
                    </div>
                    <div className='terms-content'>
                        <h2>Consultation</h2>
                        <p>The prospective client is obliged to pay an Initial Consultation Fee start of $99.99 CAD for 30 min call.</p>
                        <div className=''>
                        <a className='book-btn' href='/bookappointment'>Book Consultation <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 23 18" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9C0 8.28992 0.587626 7.71428 1.3125 7.71428L16.5188 7.71428L10.8844 2.19485C10.3719 1.69275 10.3719 0.878677 10.8844 0.376575C11.397 -0.125528 12.228 -0.125528 12.7406 0.376575L20.6156 8.09086C21.1281 8.59296 21.1281 9.40703 20.6156 9.90914L12.7406 17.6234C12.228 18.1255 11.397 18.1255 10.8844 17.6234C10.3719 17.1213 10.3719 16.3072 10.8844 15.8051L16.5188 10.2857H1.3125C0.587626 10.2857 0 9.71008 0 9Z" fill="#192451"/>
                                </svg></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <PageBottom></PageBottom>
        <Footer></Footer>
        
      </div>
    )
}
