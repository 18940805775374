import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import PageBottom from '../components/PageBottom'
import { Helmet } from 'react-helmet'
import ReactGA from "react-ga4"
import { useEffect } from 'react'


export default function FreeAssessment() {

    ReactGA.initialize("G-RQ96KPQZ6W");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/assessment", title: "Assessment" });

    useEffect(() => {
        document.title = 'Assessment | Cameron Immigration & Legal Services';
      }, []);

  return (
    <div>
        <Helmet>
            <title>Assessment | Cameron Immigration & Legal Services</title>
            <meta content="width=device-width, initial-scale=1.0" name="viewport" />
            <meta property="og:title" content="Assessment | Cameron Immigration & Legal Services" />
            <meta property="og:site_name" content="Cameron Immigration & Legal Services"/>
            <meta property="og:url" content="https://www.cameronimmigration.com/"/>
            <meta property="og:description" content="There are 100’s of options for visiting, migrating and remaining in Canada. As Canadian immigration law continues to adjust its rules and regulations, its important to have up to date knowledge. Each matter is unique and must be looked at from its own perspective, allowing us to thoroughly evaluate each matter and seek the best possible route in immigrating or remaining in Canada."/>
            <meta property="og:type" content="website"/>
            <meta property="og:image" content="https://www.cameronimmigration.com/static/media/thumbnail-img.3746441c010fec9f33b6.png"></meta>
            <meta content="There are 100’s of options for visiting, migrating and remaining in Canada. As Canadian immigration law continues to adjust its rules and regulations, its important to have up to date knowledge. Each matter is unique and must be looked at from its own perspective, allowing us to thoroughly evaluate each matter and seek the best possible route in immigrating or remaining in Canada." />
            <meta property="og:keywords" content="Leagl Services, Canada Visa, Work Visa canada, Study Visa canada, Visitor Visa canada, Super Visa canada, Family Sponsorship canada, Humanitarian & Compassionate, Express Entry canada, PNP canada, LMIA canada, Business Visitors canada, Seasonal Workers canada"/>
            <meta name="keywords" content="Leagl Services, Canada Visa, Work Visa canada, Study Visa canada, Visitor Visa canada, Super Visa canada, Family Sponsorship canada, Humanitarian & Compassionate, Express Entry canada, PNP canada, LMIA canada, Business Visitors canada, Seasonal Workers canada"/>
            <meta content="Cameron Immigration & Legal Services" name="author" />
            <meta name="title" content="Assessment | Cameron Immigration & Legal Services"/>
            <link rel="canonical" href="https://www.cameronimmigration.com/" />
            <meta http-equiv='cache-control' content='no-cache'/>
            <meta http-equiv='expires' content='0'/>
            <meta http-equiv='pragma' content='no-cache'/>
            <link rel="icon" href="/cameron-logo.jpg" type="image/png" sizes="16x16"/>

        
        </Helmet>
        <Header></Header>

        <div className='free-assessment-page'>
            <div className='page-banner'>
                <div className='container'>
                    <div className='right-sec'>
                        <div className='sec-title'>
                            <span>Assessment</span>
                        </div>
                        <div className='sec-title2'>
                            <h2>Eligibility Assessment</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className='all-assessment'>
                <div className='container'>
                    <div className='counters-sec'>
                        <div className='counters-div'>
                            <div className='conunters-nos'>
                                <h2 id='counter'>Student </h2>
                                <p>Our student assessment helps us learn how we can help you achieve your goal of becoming a student in Canada.</p>
                            </div>
                            <div class="book-button">
                                <a href="https://casecloud.ca/form/formshare?formId=f883a470-6188-4e9f-b274-5c2c58dfb5a7&siteId=CCP20201065" target='_blanc' class="">Take Assessment <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 23 18" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 9C0 8.28992 0.587626 7.71428 1.3125 7.71428L16.5188 7.71428L10.8844 2.19485C10.3719 1.69275 10.3719 0.878677 10.8844 0.376575C11.397 -0.125528 12.228 -0.125528 12.7406 0.376575L20.6156 8.09086C21.1281 8.59296 21.1281 9.40703 20.6156 9.90914L12.7406 17.6234C12.228 18.1255 11.397 18.1255 10.8844 17.6234C10.3719 17.1213 10.3719 16.3072 10.8844 15.8051L16.5188 10.2857H1.3125C0.587626 10.2857 0 9.71008 0 9Z" fill="#192451"></path></svg>
                                </a>
                            </div>
                        </div>
                        <div className='counters-div'>
                            <div className='conunters-nos'>
                                <h2>Worker </h2>
                                <p>When you complete our worker assessment, we can figure out the type of job you're looking for in Canada.</p>
                            </div>
                            <div class="book-button">
                                <a href="https://casecloud.ca/form/formshare?formId=76dbea74-28f9-4ded-b9c6-6528a4d7c484&siteId=CCP20201065" target='_blanc' class="">Take Assessment <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 23 18" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 9C0 8.28992 0.587626 7.71428 1.3125 7.71428L16.5188 7.71428L10.8844 2.19485C10.3719 1.69275 10.3719 0.878677 10.8844 0.376575C11.397 -0.125528 12.228 -0.125528 12.7406 0.376575L20.6156 8.09086C21.1281 8.59296 21.1281 9.40703 20.6156 9.90914L12.7406 17.6234C12.228 18.1255 11.397 18.1255 10.8844 17.6234C10.3719 17.1213 10.3719 16.3072 10.8844 15.8051L16.5188 10.2857H1.3125C0.587626 10.2857 0 9.71008 0 9Z" fill="#192451"></path></svg>
                                </a>
                            </div>
                        </div>
                        <div className='counters-div'>
                            <div className='conunters-nos'>
                                <h2>Visitor </h2>
                                <p>Completing our visitor assessment helps us grasp your situation for visiting Canada.</p>
                            </div>
                            <div class="book-button">
                                <a href="https://casecloud.ca/form/formshare?formId=c0ef2715-5f0d-48c3-acd7-4da7c1634247&siteId=CCP20201065" target='_blanc' class="">Take Assessment <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 23 18" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 9C0 8.28992 0.587626 7.71428 1.3125 7.71428L16.5188 7.71428L10.8844 2.19485C10.3719 1.69275 10.3719 0.878677 10.8844 0.376575C11.397 -0.125528 12.228 -0.125528 12.7406 0.376575L20.6156 8.09086C21.1281 8.59296 21.1281 9.40703 20.6156 9.90914L12.7406 17.6234C12.228 18.1255 11.397 18.1255 10.8844 17.6234C10.3719 17.1213 10.3719 16.3072 10.8844 15.8051L16.5188 10.2857H1.3125C0.587626 10.2857 0 9.71008 0 9Z" fill="#192451"></path></svg>
                                </a>
                            </div>
                        </div>
                        <div className='counters-div'>
                            <div className='conunters-nos'>
                                <h2>Post-Graduate Work Permit (PGWP) </h2>
                                <p>By completing our PGWP assessment, we can determine if you're eligible for the PGWP program.</p>
                            </div>
                            <div class="book-button">
                                <a href="https://casecloud.ca/form/formshare?formId=92f65161-0504-4c78-b225-24add8621b27&siteId=CCP20201065" target='_blanc' class="">Take Assessment <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 23 18" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 9C0 8.28992 0.587626 7.71428 1.3125 7.71428L16.5188 7.71428L10.8844 2.19485C10.3719 1.69275 10.3719 0.878677 10.8844 0.376575C11.397 -0.125528 12.228 -0.125528 12.7406 0.376575L20.6156 8.09086C21.1281 8.59296 21.1281 9.40703 20.6156 9.90914L12.7406 17.6234C12.228 18.1255 11.397 18.1255 10.8844 17.6234C10.3719 17.1213 10.3719 16.3072 10.8844 15.8051L16.5188 10.2857H1.3125C0.587626 10.2857 0 9.71008 0 9Z" fill="#192451"></path></svg>
                                </a>
                            </div>
                        </div>
                        <div className='counters-div'>
                            <div className='conunters-nos'>
                                <h2>Family Sponsorship  </h2>
                                <p>When you take our family sponsorship assessment, we learn about your family and how we can assist in reuniting you in Canada.</p>
                            </div>
                            <div class="book-button">
                                <a href="https://casecloud.ca/form/formshare?formId=fb983407-2736-41de-a1d6-ce270f721952&siteId=CCP20201065" target='_blanc' class="">Take Assessment <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 23 18" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 9C0 8.28992 0.587626 7.71428 1.3125 7.71428L16.5188 7.71428L10.8844 2.19485C10.3719 1.69275 10.3719 0.878677 10.8844 0.376575C11.397 -0.125528 12.228 -0.125528 12.7406 0.376575L20.6156 8.09086C21.1281 8.59296 21.1281 9.40703 20.6156 9.90914L12.7406 17.6234C12.228 18.1255 11.397 18.1255 10.8844 17.6234C10.3719 17.1213 10.3719 16.3072 10.8844 15.8051L16.5188 10.2857H1.3125C0.587626 10.2857 0 9.71008 0 9Z" fill="#192451"></path></svg>
                                </a>
                            </div>
                        </div>
                        <div className='counters-div'>
                            <div className='conunters-nos'>
                                <h2>Express Entry </h2>
                                <p>Completing our Express Entry assessment helps us figure out your points needed for the permanent residence Express Entry system.</p>
                            </div>
                            <div class="book-button">
                                <a href="https://casecloud.ca/form/formshare?formId=bb8f5aff-387c-4bb4-a6b5-8921e7841b74&siteId=CCP20201065" target='_blanc' class="">Take Assessment <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 23 18" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 9C0 8.28992 0.587626 7.71428 1.3125 7.71428L16.5188 7.71428L10.8844 2.19485C10.3719 1.69275 10.3719 0.878677 10.8844 0.376575C11.397 -0.125528 12.228 -0.125528 12.7406 0.376575L20.6156 8.09086C21.1281 8.59296 21.1281 9.40703 20.6156 9.90914L12.7406 17.6234C12.228 18.1255 11.397 18.1255 10.8844 17.6234C10.3719 17.1213 10.3719 16.3072 10.8844 15.8051L16.5188 10.2857H1.3125C0.587626 10.2857 0 9.71008 0 9Z" fill="#192451"></path></svg>
                                </a>
                            </div>
                        </div>
                        <div className='counters-div'>
                            <div className='conunters-nos'>
                                <h2>PNP </h2>
                                <p>By completing our PNP assessment, we can calculate the points needed for the Permanent Residence through the PNP system.</p>
                            </div>
                            <div class="book-button">
                                <a href="https://casecloud.ca/form/formshare?formId=fd93980f-0f77-4d69-a863-b2ca866ada13&siteId=CCP20201065" target='_blanc' class="">Take Assessment <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 23 18" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 9C0 8.28992 0.587626 7.71428 1.3125 7.71428L16.5188 7.71428L10.8844 2.19485C10.3719 1.69275 10.3719 0.878677 10.8844 0.376575C11.397 -0.125528 12.228 -0.125528 12.7406 0.376575L20.6156 8.09086C21.1281 8.59296 21.1281 9.40703 20.6156 9.90914L12.7406 17.6234C12.228 18.1255 11.397 18.1255 10.8844 17.6234C10.3719 17.1213 10.3719 16.3072 10.8844 15.8051L16.5188 10.2857H1.3125C0.587626 10.2857 0 9.71008 0 9Z" fill="#192451"></path></svg>
                                </a>
                            </div>
                        </div>
                        <div className='counters-div'>
                            <div className='conunters-nos'>
                                <h2>LMIA</h2>
                                <p>When you finish the LMIA assessment, we can figure out how to assist your business in meeting its labor needs.</p>
                            </div>
                            <div class="book-button">
                                <a href="https://casecloud.ca/form/formshare?formId=3fcbccbd-696b-4436-b3ed-1eb05dd11524&siteId=CCP20201065" target='_blanc' class="">Take Assessment <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 23 18" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 9C0 8.28992 0.587626 7.71428 1.3125 7.71428L16.5188 7.71428L10.8844 2.19485C10.3719 1.69275 10.3719 0.878677 10.8844 0.376575C11.397 -0.125528 12.228 -0.125528 12.7406 0.376575L20.6156 8.09086C21.1281 8.59296 21.1281 9.40703 20.6156 9.90914L12.7406 17.6234C12.228 18.1255 11.397 18.1255 10.8844 17.6234C10.3719 17.1213 10.3719 16.3072 10.8844 15.8051L16.5188 10.2857H1.3125C0.587626 10.2857 0 9.71008 0 9Z" fill="#192451"></path></svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <PageBottom></PageBottom>
        <Footer></Footer>
        
    </div>
  )
}
