import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import PageBottom from './components/PageBottom';
import Slider from "react-slick";
import OurTestiSlider from './components/OurTestiSlider';
import { Helmet } from 'react-helmet';
import ReactGA from "react-ga4";
import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={'nextarrow'}
      style={{ ...style, }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={'prevarrow'}
      style={{ ...style, }}
      onClick={onClick}
    />
  );
}

function App() {



  const servicesSlider = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    arrows: true,
     nextArrow: <NextArrow />,
     prevArrow: <PrevArrow />,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 688,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
  };

  ReactGA.initialize("G-RQ96KPQZ6W");

  // Send pageview with a custom path
  ReactGA.send({ hitType: "pageview", page: "/", title: "Cameron Immigration & Legal Services." });

  const location = useLocation();
    const lastHash = useRef('');

  useEffect(() => {
    document.title = 'Cameron Immigration & Legal Services';
    
    if (location.hash) {
      lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        document
          .getElementById(lastHash.current)
          ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        lastHash.current = '';
      }, 100);
    }
  }, [location]);

  
  return (
    
    <div className="App">
      <Helmet>
        <title>Cameron Immigration & Legal Services</title>
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />
        <meta property="og:title" content="Cameron Immigration & Legal Services" />
        <meta property="og:site_name" content="Cameron Immigration & Legal Services"/>
        <meta property="og:url" content="https://www.cameronimmigration.com/"/>
        <meta property="og:description" content="There are 100’s of options for visiting, migrating and remaining in Canada. As Canadian immigration law continues to adjust its rules and regulations, its important to have up to date knowledge. Each matter is unique and must be looked at from its own perspective, allowing us to thoroughly evaluate each matter and seek the best possible route in immigrating or remaining in Canada."/>
        <meta property="og:type" content="website"/>
        <meta property="og:image" content="https://www.cameronimmigration.com/static/media/thumbnail-img.3746441c010fec9f33b6.png"></meta>
        <meta content="There are 100’s of options for visiting, migrating and remaining in Canada. As Canadian immigration law continues to adjust its rules and regulations, its important to have up to date knowledge. Each matter is unique and must be looked at from its own perspective, allowing us to thoroughly evaluate each matter and seek the best possible route in immigrating or remaining in Canada." />
        <meta name="keywords" content="Leagl Services, Canada Visa, STudy Visa, WOrk Visa, PR Permit"/>
        <meta content="Cameron Immigration & Legal Services" name="author" />
        <meta name="title" content="Cameron Immigration & Legal Services"/>
        <link rel="canonical" href="https://www.cameronimmigration.com/" />
        <meta http-equiv='cache-control' content='no-cache'/>
        <meta http-equiv='expires' content='0'/>
        <meta http-equiv='pragma' content='no-cache'/>
        <link rel="icon" href="/cameron-logo.jpeg" type="image/png" sizes="16x16"/>
      </Helmet>
   
      <Header></Header>
      {/* Main banner start */}
      <div className='main-banner'>
        <div className=''>
          <div className='banner-flex'>
            <div className='sec-width'>
              <div className='right-sec'>
                <h1>CHANGE <span>YOUR<br></br>
                    LIFE</span> TODAY
                </h1>
                <div className='key-points'>
                  <div className='key-point1'>
                    <div>
                      <svg width="30" height="30" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="white"/>
                      </svg>
                    </div>
                    <p>Authorized Canadian Immigration Representative</p>
                  </div>
                  <div className='key-point1'>
                    <div>
                      <svg width="30" height="30" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="white"/>
                      </svg>
                    </div>
                    <p>Honest legal advice</p>
                  </div>
                  <div className='key-point1'>
                    <div>
                      <svg width="30" height="30" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="white"/>
                      </svg>
                    </div>
                    <p>Ethical</p>
                  </div>
                </div>
                <div className='btn-padding'>
                  <a className='book-button' href='/bookappointment'>Book Consultation <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 23 18" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9C0 8.28992 0.587626 7.71428 1.3125 7.71428L16.5188 7.71428L10.8844 2.19485C10.3719 1.69275 10.3719 0.878677 10.8844 0.376575C11.397 -0.125528 12.228 -0.125528 12.7406 0.376575L20.6156 8.09086C21.1281 8.59296 21.1281 9.40703 20.6156 9.90914L12.7406 17.6234C12.228 18.1255 11.397 18.1255 10.8844 17.6234C10.3719 17.1213 10.3719 16.3072 10.8844 15.8051L16.5188 10.2857H1.3125C0.587626 10.2857 0 9.71008 0 9Z" fill="#192451"/>
                  </svg>
                  </a>
                </div>
              </div>
            </div>
            <div className=''>
              <div className='left-sec'>
                <img src={require('./images/main-banner1-1.png')} alt="Cameron Immigration & Legal Services"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Main banner end */}

      {/* Main about us start */}
      <div className='about-us'>
        <div className='container'>
          <div className='row flex-custome-column-reverse'>
            <div className='col-12 col-sm-12 col-md-6 col-xl-5 col-xxl-5'>
                <div className='right-side'>
                <img className='sec-img' src={require('./images/about-us-home.png')} alt='Cameron Immigration & Legal Services About us'/>
                <img className='d-none' src={require('./images/thumbnail-img.png')} alt='Cameron Immigration & Legal Services About us'/>
              </div>
            </div>
            <div className='col-12 col-sm-12 col-md-6 col-xl-7 col-xxl-7'>
              <div className='left-side'>
                  <div className='sec-title'>
                    <span>About Us</span>
                  </div>
                  <div className='sec-title2'>
                      <h2>An easy and affordable way to immigrate</h2>
                  </div>
                  <div className='sec-text'>
                    <p>We understand what a big step this is and how stressful it can become. Having a confident, reliable professional by your side will allow you take a step back, relax and be at ease. You’re not alone, we are here to help you.</p>
                    <p>There are 100’s of options for visiting, migrating and remaining in Canada. As Canadian immigration law continues to adjust its rules and regulations, its important to have up to date knowledge. Each matter is unique and must be looked at from its own perspective, allowing us to thoroughly evaluate each matter and seek the best possible route in immigrating or remaining in Canada.</p>
                  </div>
                  <HashLink to='/about#top' className='btn'>Read more <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 23 18" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9C0 8.28992 0.587626 7.71428 1.3125 7.71428L16.5188 7.71428L10.8844 2.19485C10.3719 1.69275 10.3719 0.878677 10.8844 0.376575C11.397 -0.125528 12.228 -0.125528 12.7406 0.376575L20.6156 8.09086C21.1281 8.59296 21.1281 9.40703 20.6156 9.90914L12.7406 17.6234C12.228 18.1255 11.397 18.1255 10.8844 17.6234C10.3719 17.1213 10.3719 16.3072 10.8844 15.8051L16.5188 10.2857H1.3125C0.587626 10.2857 0 9.71008 0 9Z" fill="#192451"/>
                  </svg>
                  </HashLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Main banner end */}

      {/* Main Our Successes Achievements start */}
      <div className='our-success'>
        <div className='container'>
          <div className='right-side'>
              <div className='sec-title'>
                <span>Our Successes Achievements</span>
              </div>
              <div className='sec-title2'>
                  <h2>Accomplishments this far</h2>
              </div>
              <div className='sec-text'>
                <p>We're proud of our work helping lots of happy clients. We've dealt with many different types of visas and helped people through multiple pathways of Canadian immigration. Our experience comes from working in different industries, which helps us find the right immigration solutions for each person.</p>
              </div>
              <div className='counters-sec'>
                <div className='counters-div'>
                  <div className='counters-icon'>
                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="airplane-engines-fill">
                        <g id="Union">
                        <path d="M35.4661 10.8795C34.1176 9.80914 32.4466 10.136 31.2065 10.7142C29.9269 11.3107 28.7324 12.326 27.9595 13.2998L22.94 19.6239L18.1533 18.6175L18.413 18.2902C18.7886 17.8171 18.7094 17.1291 18.2363 16.7536L16.5229 15.3936C16.0497 15.0181 15.3618 15.0972 14.9862 15.5704L13.3666 17.611L10.5644 17.0218C9.34811 16.7661 8.09176 17.2195 7.31911 18.1929L5.43973 20.5608C5.23985 20.8126 5.16089 21.1397 5.22385 21.4549C5.2868 21.7702 5.48529 22.0418 5.76655 22.1976L8.93117 23.9501L8.19223 25.9798C8.03118 26.4222 8.17128 26.918 8.54003 27.2107C8.90878 27.5034 9.42344 27.5273 9.81771 27.27L12.1628 25.7397L16.4501 28.1139L14.0295 32.4849L9.9787 32.9507C9.53921 33.0013 9.17345 33.3116 9.05197 33.737C8.93048 34.1623 9.0772 34.619 9.4237 34.894L17.9907 41.6937C18.3372 41.9687 18.8153 42.0079 19.2019 41.793C19.5886 41.5782 19.8078 41.1515 19.7573 40.712L19.2914 36.6612L22.9987 33.3116L26.2842 36.9479L25.3262 39.5792C25.1652 40.0215 25.3053 40.5173 25.674 40.81C26.0428 41.1027 26.5574 41.1266 26.9517 40.8693L28.7607 39.6889L31.1859 42.373C31.4014 42.6116 31.711 42.7432 32.0324 42.7329C32.3537 42.7227 32.6543 42.5715 32.8541 42.3197L34.7335 39.9519C35.5062 38.9784 35.6625 37.6519 35.1373 36.5255L33.9274 33.9302L35.547 31.8896C35.9226 31.4164 35.8434 30.7284 35.3703 30.3529L33.6569 28.993C33.1837 28.6174 32.4958 28.6965 32.1202 29.1697L31.8605 29.4969L29.7936 25.0637L34.8131 18.7395C35.5861 17.7657 36.3036 16.372 36.5941 14.9904C36.8757 13.6513 36.8146 11.9498 35.4661 10.8795Z" fill="#192451"/>
                        </g>
                      </g>
                    </svg>
                  </div>
                  <div className='conunters-nos'>
                    <h2 id='counter'>15+</h2>
                    <p>Visa Categories</p>
                  </div>
                </div>
                <div className='counters-div'>
                  <div className='counters-icon'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
                    <path d="M35 17.5C35 27.165 27.165 35 17.5 35C7.83502 35 0 27.165 0 17.5C0 7.83502 7.83502 0 17.5 0C27.165 0 35 7.83502 35 17.5ZM17.5 7.65625C17.5 7.05219 17.0103 6.5625 16.4062 6.5625C15.8022 6.5625 15.3125 7.05219 15.3125 7.65625V19.6875C15.3125 20.08 15.5228 20.4424 15.8636 20.6371L23.5198 25.0121C24.0443 25.3118 24.7124 25.1296 25.0121 24.6052C25.3118 24.0807 25.1296 23.4126 24.6052 23.1129L17.5 19.0528V7.65625Z" fill="#192451"/>
                  </svg>
                  </div>
                  <div className='conunters-nos'>
                    <h2>7+</h2>
                    <p>Industry Experience </p>
                  </div>
                </div>
                <div className='counters-div'>
                  <div className='counters-icon'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
                      <path d="M17.5 0L21.1503 1.89064L25.2147 2.50665L27.0565 6.18179L29.9826 9.06915L29.3125 13.125L29.9826 17.1808L27.0565 20.0682L25.2147 23.7433L21.1503 24.3594L17.5 26.25L13.8497 24.3594L9.78533 23.7433L7.9435 20.0682L5.0174 17.1808L5.68751 13.125L5.0174 9.06915L7.9435 6.18179L9.78533 2.50665L13.8497 1.89064L17.5 0Z" fill="#192451"/>
                      <path d="M8.75001 25.799V35L17.5 32.8125L26.25 35V25.7989L21.8354 26.468L17.5 28.7135L13.1646 26.468L8.75001 25.799Z" fill="#192451"/>
                    </svg>
                  </div>
                  <div className='conunters-nos'>
                    <h2>250+</h2>
                    <p>Loyal customers </p>
                  </div>
                </div>
                <div className='counters-div'>
                  <div className='counters-icon'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
                    <path d="M5.46875 1.09375C5.46875 0.489689 5.95844 0 6.5625 0H28.4375C29.0416 0 29.5312 0.489689 29.5312 1.09375C29.5312 2.26959 29.5053 3.38869 29.4558 4.45353C32.5966 4.94279 35 7.65947 35 10.9375C35 14.5619 32.0619 17.5 28.4375 17.5C27.9368 17.5 27.4484 17.4437 26.9786 17.337C25.2508 21.4173 22.8702 23.4121 20.7812 23.9257V28.6773L23.8992 29.4568C24.3235 29.5628 24.7223 29.7526 25.0721 30.015L29.0938 33.0312C29.4704 33.3137 29.624 33.8055 29.4751 34.2521C29.3262 34.6987 28.9083 35 28.4375 35H6.5625C6.09172 35 5.67375 34.6987 5.52488 34.2521C5.376 33.8055 5.52962 33.3137 5.90625 33.0312L9.92785 30.015C10.2777 29.7526 10.6765 29.5628 11.1008 29.4568L14.2188 28.6773V23.9257C12.1298 23.4121 9.7492 21.4173 8.02144 17.337C7.55163 17.4437 7.0632 17.5 6.5625 17.5C2.93813 17.5 0 14.5619 0 10.9375C0 7.65947 2.40343 4.94279 5.54425 4.45353C5.49472 3.38869 5.46875 2.26959 5.46875 1.09375ZM5.6853 6.65046C3.68944 7.05667 2.1875 8.82169 2.1875 10.9375C2.1875 13.3537 4.14625 15.3125 6.5625 15.3125C6.79982 15.3125 7.03231 15.2937 7.2587 15.2576C6.5316 12.9617 5.97325 10.127 5.6853 6.65046ZM27.7413 15.2576C27.9677 15.2937 28.2002 15.3125 28.4375 15.3125C30.8537 15.3125 32.8125 13.3537 32.8125 10.9375C32.8125 8.82169 31.3106 7.05667 29.3147 6.65046C29.0268 10.127 28.4684 12.9617 27.7413 15.2576Z" fill="#192451"/>
                  </svg>
                  </div>
                  <div className='conunters-nos'>
                    <h2>98%</h2>
                    <p>Happy clients  </p>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
      {/* Main Our Successes Achievements end */}

      {/* Main Our services start */}
      <div className='our-services'>
        <div className='container'>
           <div className='right-side'>
              <div className='sec-title'>
                <span>Our Services</span>
              </div>
              <div className='sec-title2'>
                  <h2>Skilled help for smooth visa processes</h2>
              </div>
              <div className='sec-text'>
                <p>We have in-depth knowledge of the complex immigration systems, regulations, and processes of various countries.</p>
              </div>
            </div>
            <div className='our-services-slider'>
              <Slider {...servicesSlider}>
                  <div className='sliderCard' >
                      <img src={require('./images/work-slide.png')} alt="Work Visa"/>
                        <div className='slideNumber'>
                            <span>01</span>
                        </div>
                        <div className='card-details'>
                            <div className='visa-title'>
                                <p>Work Visa</p>
                            </div>
                            <div className='readmore'>
                              <HashLink smooth to="/worker-study-visitor#work-visa" className='btn'>Read more <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 23 18" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9C0 8.28992 0.587626 7.71428 1.3125 7.71428L16.5188 7.71428L10.8844 2.19485C10.3719 1.69275 10.3719 0.878677 10.8844 0.376575C11.397 -0.125528 12.228 -0.125528 12.7406 0.376575L20.6156 8.09086C21.1281 8.59296 21.1281 9.40703 20.6156 9.90914L12.7406 17.6234C12.228 18.1255 11.397 18.1255 10.8844 17.6234C10.3719 17.1213 10.3719 16.3072 10.8844 15.8051L16.5188 10.2857H1.3125C0.587626 10.2857 0 9.71008 0 9Z" fill="#192451"/>
                                </svg>
                              </HashLink>
                            </div>
                        </div>
                  </div>
                  <div className='sliderCard' >
                      <img src={require('./images/study-slide.png')} alt="Study Visa"/>
                        <div className='slideNumber'>
                            <span>02</span>
                        </div>
                        <div className='card-details'>
                            <div className='visa-title'>
                                <p>Study Visa</p>
                            </div>
                            <div className='readmore'>
                            <HashLink smooth to="/worker-study-visitor#study-visa" className='btn'>Read more <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 23 18" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9C0 8.28992 0.587626 7.71428 1.3125 7.71428L16.5188 7.71428L10.8844 2.19485C10.3719 1.69275 10.3719 0.878677 10.8844 0.376575C11.397 -0.125528 12.228 -0.125528 12.7406 0.376575L20.6156 8.09086C21.1281 8.59296 21.1281 9.40703 20.6156 9.90914L12.7406 17.6234C12.228 18.1255 11.397 18.1255 10.8844 17.6234C10.3719 17.1213 10.3719 16.3072 10.8844 15.8051L16.5188 10.2857H1.3125C0.587626 10.2857 0 9.71008 0 9Z" fill="#192451"/>
                                </svg>
                              </HashLink>
                            </div>
                        </div>
                  </div>
                  <div className='sliderCard' >
                      <img src={require('./images/visitor-slide.png')} alt="Visitor Visa"/>
                        <div className='slideNumber'>
                            <span>03</span>
                        </div>
                        <div className='card-details'>
                            <div className='visa-title'>
                                <p>Visitor Visa</p>
                            </div>
                            <div className='readmore'>
                            <HashLink smooth to="/worker-study-visitor#visitor-visa" className='btn'>Read more <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 23 18" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9C0 8.28992 0.587626 7.71428 1.3125 7.71428L16.5188 7.71428L10.8844 2.19485C10.3719 1.69275 10.3719 0.878677 10.8844 0.376575C11.397 -0.125528 12.228 -0.125528 12.7406 0.376575L20.6156 8.09086C21.1281 8.59296 21.1281 9.40703 20.6156 9.90914L12.7406 17.6234C12.228 18.1255 11.397 18.1255 10.8844 17.6234C10.3719 17.1213 10.3719 16.3072 10.8844 15.8051L16.5188 10.2857H1.3125C0.587626 10.2857 0 9.71008 0 9Z" fill="#192451"/>
                                </svg>
                              </HashLink>
                            </div>
                        </div>
                  </div>
                  <div className='sliderCard' >
                      <img src={require('./images/super-visa-slide.png')} alt="Super Visa"/>
                        <div className='slideNumber'>
                            <span>04</span>
                        </div>
                        <div className='card-details'>
                            <div className='visa-title'>
                                <p>Super Visa</p>
                            </div>
                            <div className='readmore'>
                            <HashLink smooth to="/worker-study-visitor#super-visa" className='btn'>Read more <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 23 18" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9C0 8.28992 0.587626 7.71428 1.3125 7.71428L16.5188 7.71428L10.8844 2.19485C10.3719 1.69275 10.3719 0.878677 10.8844 0.376575C11.397 -0.125528 12.228 -0.125528 12.7406 0.376575L20.6156 8.09086C21.1281 8.59296 21.1281 9.40703 20.6156 9.90914L12.7406 17.6234C12.228 18.1255 11.397 18.1255 10.8844 17.6234C10.3719 17.1213 10.3719 16.3072 10.8844 15.8051L16.5188 10.2857H1.3125C0.587626 10.2857 0 9.71008 0 9Z" fill="#192451"/>
                                </svg>
                              </HashLink>
                            </div>
                        </div>
                  </div>
                  <div className='sliderCard' >
                      <img src={require('./images/family-Sponsorship-slider.png')} alt="Family Sponsorship"/>
                        <div className='slideNumber'>
                            <span>05</span>
                        </div>
                        <div className='card-details'>
                            <div className='visa-title'>
                                <p>Family Sponsorship</p>
                            </div>
                            <div className='readmore'>
                            <HashLink smooth to="/permanent-status#family-sponsorship" className='btn'>Read more <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 23 18" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9C0 8.28992 0.587626 7.71428 1.3125 7.71428L16.5188 7.71428L10.8844 2.19485C10.3719 1.69275 10.3719 0.878677 10.8844 0.376575C11.397 -0.125528 12.228 -0.125528 12.7406 0.376575L20.6156 8.09086C21.1281 8.59296 21.1281 9.40703 20.6156 9.90914L12.7406 17.6234C12.228 18.1255 11.397 18.1255 10.8844 17.6234C10.3719 17.1213 10.3719 16.3072 10.8844 15.8051L16.5188 10.2857H1.3125C0.587626 10.2857 0 9.71008 0 9Z" fill="#192451"/>
                                </svg>
                              </HashLink>
                            </div>
                        </div>
                  </div>
                  <div className='sliderCard' >
                      <img src={require('./images/humanitarian-slider.png')} alt="Humanitarian & Compassionate"/>
                        <div className='slideNumber'>
                            <span>06</span>
                        </div>
                        <div className='card-details'>
                            <div className='visa-title'>
                                <p>Humanitarian & Compassionate</p>
                            </div>
                            <div className='readmore'>
                            <HashLink smooth to="/permanent-status#humanitarian-and-compassionate" className='btn'>Read more <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 23 18" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9C0 8.28992 0.587626 7.71428 1.3125 7.71428L16.5188 7.71428L10.8844 2.19485C10.3719 1.69275 10.3719 0.878677 10.8844 0.376575C11.397 -0.125528 12.228 -0.125528 12.7406 0.376575L20.6156 8.09086C21.1281 8.59296 21.1281 9.40703 20.6156 9.90914L12.7406 17.6234C12.228 18.1255 11.397 18.1255 10.8844 17.6234C10.3719 17.1213 10.3719 16.3072 10.8844 15.8051L16.5188 10.2857H1.3125C0.587626 10.2857 0 9.71008 0 9Z" fill="#192451"/>
                                </svg>
                              </HashLink>
                            </div>
                        </div>
                  </div>
                  <div className='sliderCard' >
                      <img src={require('./images/express-entry-slider.png')} alt="Express Entry"/>
                        <div className='slideNumber'>
                            <span>07</span>
                        </div>
                        <div className='card-details'>
                            <div className='visa-title'>
                                <p>Express Entry</p>
                            </div>
                            <div className='readmore'>
                            <HashLink smooth to="/permanent-status#express-entry" className='btn'>Read more <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 23 18" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9C0 8.28992 0.587626 7.71428 1.3125 7.71428L16.5188 7.71428L10.8844 2.19485C10.3719 1.69275 10.3719 0.878677 10.8844 0.376575C11.397 -0.125528 12.228 -0.125528 12.7406 0.376575L20.6156 8.09086C21.1281 8.59296 21.1281 9.40703 20.6156 9.90914L12.7406 17.6234C12.228 18.1255 11.397 18.1255 10.8844 17.6234C10.3719 17.1213 10.3719 16.3072 10.8844 15.8051L16.5188 10.2857H1.3125C0.587626 10.2857 0 9.71008 0 9Z" fill="#192451"/>
                                </svg>
                              </HashLink>
                            </div>
                        </div>
                  </div>
                  <div className='sliderCard' >
                      <img src={require('./images/pnp-slider.png')} alt="Provincial Nominee Program (PNP)"/>
                        <div className='slideNumber'>
                            <span>08</span>
                        </div>
                        <div className='card-details'>
                            <div className='visa-title'>
                                <p>PNP</p>
                            </div>
                            <div className='readmore'>
                            <HashLink smooth to="/permanent-status#PNP" className='btn'>Read more <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 23 18" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9C0 8.28992 0.587626 7.71428 1.3125 7.71428L16.5188 7.71428L10.8844 2.19485C10.3719 1.69275 10.3719 0.878677 10.8844 0.376575C11.397 -0.125528 12.228 -0.125528 12.7406 0.376575L20.6156 8.09086C21.1281 8.59296 21.1281 9.40703 20.6156 9.90914L12.7406 17.6234C12.228 18.1255 11.397 18.1255 10.8844 17.6234C10.3719 17.1213 10.3719 16.3072 10.8844 15.8051L16.5188 10.2857H1.3125C0.587626 10.2857 0 9.71008 0 9Z" fill="#192451"/>
                                </svg>
                              </HashLink>
                            </div>
                        </div>
                  </div>
                  <div className='sliderCard' >
                      <img src={require('./images/lmia-slider.png')} alt="Labour Market Impact Assessment (LMIA)"/>
                        <div className='slideNumber'>
                            <span>09</span>
                        </div>
                        <div className='card-details'>
                            <div className='visa-title'>
                                <p>LMIA</p>
                            </div>
                            <div className='readmore'>
                            <HashLink smooth to="/corporate-and-business-services#LMIA" className='btn'>Read more <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 23 18" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9C0 8.28992 0.587626 7.71428 1.3125 7.71428L16.5188 7.71428L10.8844 2.19485C10.3719 1.69275 10.3719 0.878677 10.8844 0.376575C11.397 -0.125528 12.228 -0.125528 12.7406 0.376575L20.6156 8.09086C21.1281 8.59296 21.1281 9.40703 20.6156 9.90914L12.7406 17.6234C12.228 18.1255 11.397 18.1255 10.8844 17.6234C10.3719 17.1213 10.3719 16.3072 10.8844 15.8051L16.5188 10.2857H1.3125C0.587626 10.2857 0 9.71008 0 9Z" fill="#192451"/>
                                </svg>
                              </HashLink>
                            </div>
                        </div>
                  </div>
                  <div className='sliderCard' >
                      <img src={require('./images/business-visitors-slider.png')} alt="Business Visitors"/>
                        <div className='slideNumber'>
                            <span>10</span>
                        </div>
                        <div className='card-details'>
                            <div className='visa-title'>
                                <p>Business Visitors</p>
                            </div>
                            <div className='readmore'>
                            <HashLink smooth to="/corporate-and-business-services#business-visitors" className='btn'>Read more <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 23 18" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9C0 8.28992 0.587626 7.71428 1.3125 7.71428L16.5188 7.71428L10.8844 2.19485C10.3719 1.69275 10.3719 0.878677 10.8844 0.376575C11.397 -0.125528 12.228 -0.125528 12.7406 0.376575L20.6156 8.09086C21.1281 8.59296 21.1281 9.40703 20.6156 9.90914L12.7406 17.6234C12.228 18.1255 11.397 18.1255 10.8844 17.6234C10.3719 17.1213 10.3719 16.3072 10.8844 15.8051L16.5188 10.2857H1.3125C0.587626 10.2857 0 9.71008 0 9Z" fill="#192451"/>
                                </svg>
                              </HashLink>
                            </div>
                        </div>
                  </div>
                  <div className='sliderCard' >
                      <img src={require('./images/seasonal-workers-slider.png')} alt="Seasonal Workers"/>
                        <div className='slideNumber'>
                            <span>11</span>
                        </div>
                        <div className='card-details'>
                            <div className='visa-title'>
                                <p>Seasonal Workers</p>
                            </div>
                            <div className='readmore'>
                            <HashLink smooth to="/corporate-and-business-services#seasonal-workers" className='btn'>Read more <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 23 18" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9C0 8.28992 0.587626 7.71428 1.3125 7.71428L16.5188 7.71428L10.8844 2.19485C10.3719 1.69275 10.3719 0.878677 10.8844 0.376575C11.397 -0.125528 12.228 -0.125528 12.7406 0.376575L20.6156 8.09086C21.1281 8.59296 21.1281 9.40703 20.6156 9.90914L12.7406 17.6234C12.228 18.1255 11.397 18.1255 10.8844 17.6234C10.3719 17.1213 10.3719 16.3072 10.8844 15.8051L16.5188 10.2857H1.3125C0.587626 10.2857 0 9.71008 0 9Z" fill="#192451"/>
                                </svg>
                              </HashLink>
                            </div>
                        </div>
                  </div>
              </Slider>
            </div>
            <div className='right-side pt-5'>
              <div className='sec-text'>
                <p>From start to finish, we're dedicated to simplifying the complexities of visa procedures, making your journey towards obtaining a visa as straightforward as possible.</p>
              </div>
            </div>
        </div>
      </div>
      {/* Main Our services end */}

      {/* Main why choose us start */}
      <div className='why-choose-us'>
        <div className='container'>
          <div className='row flex-custome-column-reverse'>
            <div className='col-12 col-sm-12 col-md-6 col-xl-5 col-xxl-5'>
                <div className='right-side'>
                <img className='sec-img' src={require('./images/why-choose.png')} alt='Cameron Immigration & Legal Services About us'/>
              </div>
            </div>
            <div className='col-12 col-sm-12 col-md-6 col-xl-7 col-xxl-7'>
              <div className='left-side'>
                  <div className='sec-title'>
                    <span>Why Choose Us?</span>
                  </div>
                  <div className='sec-title2'>
                      <h2>Extensive background in immigration and visa consulting</h2>
                  </div>
                  <div className='sec-text'>
                    <p>After training with top immigration lawyers in Canada for many years, we're experienced in assisting others through the visa process.</p>
                  </div>
                  <div className='key-points'>
                    <div className='key-point1'>
                      <svg width="30" height="30" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                      </svg>
                      <p>Honest</p>
                    </div>
                    <div className='key-point1'>
                      <svg width="30" height="30" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                      </svg>
                      <p>Trustworthy</p>
                    </div>
                    <div className='key-point1'>
                      <svg width="30" height="30" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                      </svg>
                      <p>Ethical</p>
                    </div>
                    <div className='key-point1'>
                      <svg width="30" height="30" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                      </svg>
                      <p>Passionate</p>
                    </div>
                    <div className='key-point1'>
                      <svg width="30" height="30" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                      </svg>
                      <p>Dedicated</p>
                    </div>
                    <div className='key-point1'>
                      <svg width="30" height="30" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                      </svg>
                      <p>Transparent</p>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Main why choose us end */}

      {/* Main our work process start */}
      <div className='our-work-process'>
        <div className='container'>
            <div className='right-side'>
              <div className='sec-title'>
                <span>Our Work Process</span>
              </div>
              <div className='sec-title2'>
                  <h2>How our process works</h2>
              </div>
              <div className='sec-text'>
                <p>Our process begins by understanding your unique situation. We start with an in-depth consultation to grasp your specific needs and goals. Then, we tailor a personalized plan, guiding you through each step of the immigration process. From gathering necessary documents to navigating applications, we provide support and expertise, ensuring a smooth journey towards your immigration goals. Throughout the process, we keep communication open, updating you on progress and addressing any concerns you may have.</p>
              </div>
            </div>
            <div className='process-steps'>
              <div className='row'>
                <div className='col-12 col-sm-12 col-md-6 col-xl-4 col-xxl-4'>
                  <div className='process-steps-card'>
                      <div className='steps-nos'>
                        <span>1</span>
                      </div>
                      <div className='steps-title'>
                        <h2>Free Assessment</h2>
                      </div>
                      <div className='steps-content'>
                        <p>We invite you to complete a free assessment here on our website – we aim to reach out to you within 48 hours.</p>
                      </div>
                  </div>
                </div>
                <div className='col-12 col-sm-12 col-md-6 col-xl-4 col-xxl-4'>
                  <div className='process-steps-card'>
                      <div className='steps-nos'>
                        <span>2</span>
                      </div>
                      <div className='steps-title'>
                        <h2>Consultation </h2>
                      </div>
                      <div className='steps-content'>
                        <p>In our meeting, we'll get the details we need to understand your situation and make a plan just for you.</p>
                      </div>
                  </div>
                </div>
                <div className='col-12 col-sm-12 col-md-6 col-xl-4 col-xxl-4'>
                  <div className='process-steps-card'>
                      <div className='steps-nos'>
                        <span>3</span>
                      </div>
                      <div className='steps-title'>
                        <h2>Service Agreement </h2>
                      </div>
                      <div className='steps-content'>
                        <p>We’ll sign a document that explains what we’ll do for you and what you need to do for us to complete our work.</p>
                      </div>
                  </div>
                </div>
                <div className='col-12 col-sm-12 col-md-6 col-xl-4 col-xxl-4'>
                  <div className='process-steps-card'>
                      <div className='steps-nos'>
                        <span>4</span>
                      </div>
                      <div className='steps-title'>
                        <h2>Forms and Documents </h2>
                      </div>
                      <div className='steps-content'>
                        <p>We'll gather all the info and documents we need through our client portal to finish processing your matter.</p>
                      </div>
                  </div>
                </div>
                <div className='col-12 col-sm-12 col-md-6 col-xl-4 col-xxl-4'>
                  <div className='process-steps-card'>
                      <div className='steps-nos'>
                        <span>5</span>
                      </div>
                      <div className='steps-title'>
                        <h2>Authorized Representation</h2>
                      </div>
                      <div className='steps-content'>
                        <p>We'll represent you and handle any letters or messages after we send in your application.</p>
                      </div>
                  </div>
                </div>
                <div className='col-12 col-sm-12 col-md-6 col-xl-4 col-xxl-4'>
                  <div className='process-steps-card'>
                      <div className='steps-nos'>
                        <span>6</span>
                      </div>
                      <div className='steps-title'>
                        <h2>Final decision </h2>
                      </div>
                      <div className='steps-content'>
                        <p>We remain committed to our work until a decision is made and your matter is finalized.</p>
                      </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
      {/* Main our work process end */}

      {/* Main our testimonials start */}
      <div className='our-testi-slider'>
        <div className='container'>
          <div className='right-side'>
              <div className='sec-title'>
                <span>Our Testimonials</span>
              </div>
              <div className='sec-title2'>
                  <h2>Happy Clients reflect on their experience with us</h2>
              </div>
            </div>
            <OurTestiSlider></OurTestiSlider>
            <div className='py-5 text-center'>
              <Link to='/testimonials#top' className='btn'>All Testimonials <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 23 18" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9C0 8.28992 0.587626 7.71428 1.3125 7.71428L16.5188 7.71428L10.8844 2.19485C10.3719 1.69275 10.3719 0.878677 10.8844 0.376575C11.397 -0.125528 12.228 -0.125528 12.7406 0.376575L20.6156 8.09086C21.1281 8.59296 21.1281 9.40703 20.6156 9.90914L12.7406 17.6234C12.228 18.1255 11.397 18.1255 10.8844 17.6234C10.3719 17.1213 10.3719 16.3072 10.8844 15.8051L16.5188 10.2857H1.3125C0.587626 10.2857 0 9.71008 0 9Z" fill="#192451"/>
              </svg>
              </Link>
            </div>
        </div>
      </div>
      {/* Main our testimonials end */}


      <PageBottom></PageBottom>
      <Footer></Footer>
      
    </div>
  );
}

export default App;
