import React, { Component } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import PageBottom from '../components/PageBottom'
import { Helmet } from 'react-helmet'
import { HashLink as Link } from 'react-router-hash-link'
import ReactGA from "react-ga4"
import { useEffect } from 'react'

export default function PermanentStatus(){


    ReactGA.initialize("G-RQ96KPQZ6W");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/permanent-status", title: "Permanent Status" });

    useEffect(() => {
        document.title = 'Permanent Status | Cameron Immigration & Legal Services';
      }, []);

    return (
      <div>
        <Helmet>
            <title>Permanent Status | Cameron Immigration & Legal Services</title>
            <meta content="width=device-width, initial-scale=1.0" name="viewport" />
            <meta property="og:title" content="Permanent Status | Cameron Immigration & Legal Services" />
            <meta property="og:site_name" content="Cameron Immigration & Legal Services"/>
            <meta property="og:url" content="https://www.cameronimmigration.com/"/>
            <meta property="og:image" content="https://www.cameronimmigration.com/static/media/thumbnail-img.3746441c010fec9f33b6.png"></meta>
            <meta property="og:description" content="There are 100’s of options for visiting, migrating and remaining in Canada. As Canadian immigration law continues to adjust its rules and regulations, its important to have up to date knowledge. Each matter is unique and must be looked at from its own perspective, allowing us to thoroughly evaluate each matter and seek the best possible route in immigrating or remaining in Canada."/>
            <meta property="og:type" content="website"/>
            <meta content="There are 100’s of options for visiting, migrating and remaining in Canada. As Canadian immigration law continues to adjust its rules and regulations, its important to have up to date knowledge. Each matter is unique and must be looked at from its own perspective, allowing us to thoroughly evaluate each matter and seek the best possible route in immigrating or remaining in Canada." />
            <meta property="og:keywords" content="Leagl Services, Canada Visa, Work Visa canada, Study Visa canada, Visitor Visa canada, Super Visa canada, Family Sponsorship canada, Humanitarian & Compassionate, Express Entry canada, PNP canada, LMIA canada, Business Visitors canada, Seasonal Workers canada"/>
            <meta name="keywords" content="Leagl Services, Canada Visa, Work Visa canada, Study Visa canada, Visitor Visa canada, Super Visa canada, Family Sponsorship canada, Humanitarian & Compassionate, Express Entry canada, PNP canada, LMIA canada, Business Visitors canada, Seasonal Workers canada"/>
            <meta content="Cameron Immigration & Legal Services" name="author" />
            <meta name="title" content="Permanent Status | Cameron Immigration & Legal Services"/>
            <link rel="canonical" href="https://www.cameronimmigration.com/" />
            <meta http-equiv='cache-control' content='no-cache'/>
            <meta http-equiv='expires' content='0'/>
            <meta http-equiv='pragma' content='no-cache'/>
            <link rel="icon" href="/cameron-logo.jpg" type="image/png" sizes="16x16"/>

           
        </Helmet>
        <Header></Header>
        <div className='permanent-status-page'>
            <div className='page-banner'>
                <div className='container'>
                    <div className='right-sec'>
                        <div className='sec-title'>
                            <span>Permanent Status</span>
                        </div>
                        <div className='sec-title2'>
                            <h2>Becoming a Permanent Resident of Canada</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className='all-services'>
                <div className='container'>
                    <p className='top-text'>
                    Canada has a wide range of migration programs—more than 100—tailored for skilled workers, business folks, and families. Here's a quick glimpse of some popular programs. To see if you qualify, you can take one of our assessments or reach out to book an appointment with us. We'll help guide you through the options that best fit your situation.
                    </p>
                    <div className='service-details' id='family-sponsorship'>
                        <div className='row'>
                            <div className='col-12 col-sm-12 col-md-7 col-xl-8 col-xxl-8'>
                                <div className='left-side'>
                                    <div className='sec-title2'>
                                        <h2>Family Sponsorship</h2>
                                    </div>
                                    <div className='sec-text'>
                                        <p>
                                        Family reunification is a key part of Canadian immigration. This category focuses on reuniting spouses, dependents, parents, and grandparents with Canadian citizens or permanent residents. When you sponsor your relatives to come to Canada, they can live, study, and work in the country if they become permanent residents.
                                        </p>
                                        <p>We're here to support you in understanding this program better and to help you bring your family to Canada. Just reach out, and we'll guide you through the process.</p>
                                    </div>
                                    <div className='basic-eligibility'>
                                        <h3>Basic eligibility requirements:</h3>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>To sponsor family members to Canada, you typically need.</p>
                                        </div>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>To be a Canadian citizen, a permanent resident, or in some cases, a registered Indian under the Canadian Indian Act.</p>
                                        </div>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>You must be at least 18 years old and meet certain financial requirements to support your sponsored relatives.</p>
                                        </div>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>The family members you sponsor must also meet eligibility criteria, like passing medical exams and security checks.</p>
                                        </div>
                                    </div>
                                    <div className='book-button'>
                                        <Link smooth to='/bookappointment#top' className=''>Book Consultation <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 23 18" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9C0 8.28992 0.587626 7.71428 1.3125 7.71428L16.5188 7.71428L10.8844 2.19485C10.3719 1.69275 10.3719 0.878677 10.8844 0.376575C11.397 -0.125528 12.228 -0.125528 12.7406 0.376575L20.6156 8.09086C21.1281 8.59296 21.1281 9.40703 20.6156 9.90914L12.7406 17.6234C12.228 18.1255 11.397 18.1255 10.8844 17.6234C10.3719 17.1213 10.3719 16.3072 10.8844 15.8051L16.5188 10.2857H1.3125C0.587626 10.2857 0 9.71008 0 9Z" fill="#192451"/>
                                    </svg></Link>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-sm-12 col-md-5 col-xl-4 col-xxl-4'>
                                <img src={require('../images/family.png')} alt='Family Sponsorship'/>
                            </div>
                        </div>
                    </div>
                    <div className='service-details' id='humanitarian-and-compassionate'>
                        <div className='row flex-custome-column-reverse'>
                            <div className='col-12 col-sm-12 col-md-5 col-xl-4 col-xxl-4'>
                                <img src={require('../images/humanitarian.png')} alt='Humanitarian & Compassionate'/>
                            </div>
                            <div className='col-12 col-sm-12 col-md-7 col-xl-8 col-xxl-8'>
                                <div className='left-side'>
                                    <div className='sec-title2'>
                                        <h2>Humanitarian & Compassionate</h2>
                                    </div>
                                    <div className='sec-text'>
                                        <p>
                                        The Humanitarian and Compassionate (H&C) application in Canada is a way for people who don't meet the regular immigration rules to ask for special consideration based on their individual circumstances. It's for those facing exceptional situations or hardships that make it difficult for them to leave Canada. People apply through this program seeking permission to stay in Canada for reasons like safety concerns or challenging situations back in their home country, among other reasons. 
                                        </p>
                                        <p>
                                        You're welcome to contact us for more details on this option. You can also fill out one of our assessments, and we'll get in touch with you to discuss further. We're here to help!
                                        </p>
                                    </div>
                                    <div className='book-button'>
                                        <Link smooth to='/bookappointment#top' className=''>Book Consultation <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 23 18" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9C0 8.28992 0.587626 7.71428 1.3125 7.71428L16.5188 7.71428L10.8844 2.19485C10.3719 1.69275 10.3719 0.878677 10.8844 0.376575C11.397 -0.125528 12.228 -0.125528 12.7406 0.376575L20.6156 8.09086C21.1281 8.59296 21.1281 9.40703 20.6156 9.90914L12.7406 17.6234C12.228 18.1255 11.397 18.1255 10.8844 17.6234C10.3719 17.1213 10.3719 16.3072 10.8844 15.8051L16.5188 10.2857H1.3125C0.587626 10.2857 0 9.71008 0 9Z" fill="#192451"/>
                                    </svg></Link>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className='service-details' id='express-entry'>
                        <div className='row'>
                            <div className='col-12 col-sm-12 col-md-7 col-xl-8 col-xxl-8'>
                                <div className='left-side'>
                                    <div className='sec-title2'>
                                        <h2>Express Entry</h2>
                                    </div>
                                    <div className='sec-text'>
                                        <p>
                                        The Express Entry application in Canada is a fast-track system for skilled workers who want to become permanent residents. It manages applications for three main programs: the Federal Skilled Worker Program, the Federal Skilled Trades Program, and the Canadian Experience Class. Applicants create an online profile and are ranked based on factors like education, work experience, and language skills. The highest-ranked candidates are invited to apply for permanent residency.
                                        </p>
                                    </div>
                                    <div className='basic-eligibility'>
                                        <h3>Basic eligibility requirements:</h3>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>Language Skills: Applicants need to show they're good at listening, reading, writing, and speaking in the required language for their job.</p>
                                        </div>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>Canadian Experience Class (CEC) Eligibility: Candidates must have at least 1 year of skilled work experience in Canada within the past 3 years before applying. This experience can be gained:</p>
                                        </div>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>Working full-time (at least 30 hours a week) for 12 months at one job.</p>
                                        </div>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>Accumulating part-time hours that add up to full-time work.</p>
                                        </div>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>Combining hours from multiple jobs to reach 30 hours per week for 12 months.</p>
                                        </div>
                                        <div className='key-point1'>
                                            <div>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                            </svg>
                                            </div>
                                            <p>Work Authorization: Candidates should have had the necessary temporary resident status that allowed them to work in Canada while gaining this work experience.</p>
                                        </div>
                                        <div className='key-point1'>
                                            <div>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                            </svg>
                                            </div>
                                            <p>Job Experience: The work experience should align with job categories classified as NOC 0, 1, 2, or 3 in the Canadian National Occupational Classification (NOC).</p>
                                        </div>
                                    </div>
                                    <div className='book-button'>
                                        <Link smooth to='/bookappointment#top' className=''>Book Consultation <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 23 18" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9C0 8.28992 0.587626 7.71428 1.3125 7.71428L16.5188 7.71428L10.8844 2.19485C10.3719 1.69275 10.3719 0.878677 10.8844 0.376575C11.397 -0.125528 12.228 -0.125528 12.7406 0.376575L20.6156 8.09086C21.1281 8.59296 21.1281 9.40703 20.6156 9.90914L12.7406 17.6234C12.228 18.1255 11.397 18.1255 10.8844 17.6234C10.3719 17.1213 10.3719 16.3072 10.8844 15.8051L16.5188 10.2857H1.3125C0.587626 10.2857 0 9.71008 0 9Z" fill="#192451"/>
                                    </svg></Link>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-sm-12 col-md-5 col-xl-4 col-xxl-4'>
                                <img src={require('../images/express-entry.png')} alt='Express Entry'/>
                            </div>
                        </div>
                    </div>
                    <div className='service-details' id='PNP'>
                        <div className='row flex-custome-column-reverse'>
                            <div className='col-12 col-sm-12 col-md-5 col-xl-4 col-xxl-4'>
                                <img src={require('../images/pnp.png')} alt='Provincial Nominee Program (PNP)'/>
                            </div>
                            <div className='col-12 col-sm-12 col-md-7 col-xl-8 col-xxl-8'>
                                <div className='left-side'>
                                    <div className='sec-title2'>
                                        <h2>Provincial Nominee Program (PNP)</h2>
                                    </div>
                                    <div className='sec-text'>
                                        <p>
                                        The Canadian Provincial Nominee Program (PNP) allows provinces and territories in Canada to nominate individuals who want to immigrate and settle in a specific province or territory. Each province has its own PNP with unique criteria and streams tailored to their specific labor market and economic needs. Applicants chosen by a province or territory through their PNP may receive a nomination, which can speed up the overall immigration process to become a permanent resident of Canada.
                                        </p>
                                        
                                    </div>
                                    <div className='basic-eligibility'>
                                        <h3>Basic eligibility requirements:</h3>
                                        <div className='key-point1'>
                                        <div>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                            </svg>
                                        </div>
                                        <p>Have the skills, education, and work experience needed in the specific province's labor market.</p>
                                        </div>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>Meet the criteria of the province or territory's PNP stream you're applying to.</p>
                                        </div>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>Intend to live in the province that nominates you.</p>
                                        </div>
                                        <div className='key-point1'>
                                            <div>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                            </svg>
                                            </div>
                                            <p>Get a nomination certificate from the province or territory.</p>
                                            
                                        </div>
                                    </div>
                                    <div className='book-button'>
                                        <Link smooth to='/bookappointment#top' className=''>Book Consultation <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 23 18" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9C0 8.28992 0.587626 7.71428 1.3125 7.71428L16.5188 7.71428L10.8844 2.19485C10.3719 1.69275 10.3719 0.878677 10.8844 0.376575C11.397 -0.125528 12.228 -0.125528 12.7406 0.376575L20.6156 8.09086C21.1281 8.59296 21.1281 9.40703 20.6156 9.90914L12.7406 17.6234C12.228 18.1255 11.397 18.1255 10.8844 17.6234C10.3719 17.1213 10.3719 16.3072 10.8844 15.8051L16.5188 10.2857H1.3125C0.587626 10.2857 0 9.71008 0 9Z" fill="#192451"/>
                                    </svg></Link>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <PageBottom></PageBottom>
        <Footer></Footer>
        
      </div>
    )
}
