import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import PageBottom from '../components/PageBottom'
import { Helmet } from 'react-helmet'
import ReactGA from "react-ga4"
import { useEffect } from 'react'


export default function AboutUs() {

    ReactGA.initialize("G-RQ96KPQZ6W");

    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/about", title: "About Us" });

    useEffect(() => {
        document.title = 'About Us | Cameron Immigration & Legal Services';
      }, []);

  return (
    <div>
        <Helmet>
            <title> About Us | Cameron Immigration & Legal Services</title>
            <meta content="width=device-width, initial-scale=1.0" name="viewport" />
            <meta property="og:title" content="About Us | Cameron Immigration & Legal Services" />
            <meta property="og:site_name" content="Cameron Immigration & Legal Services"/>
            <meta property="og:url" content="https://www.cameronimmigration.com/"/>
            <meta property="og:description" content="There are 100’s of options for visiting, migrating and remaining in Canada. As Canadian immigration law continues to adjust its rules and regulations, its important to have up to date knowledge. Each matter is unique and must be looked at from its own perspective, allowing us to thoroughly evaluate each matter and seek the best possible route in immigrating or remaining in Canada."/>
            <meta property="og:type" content="website"/>
            <meta property="og:image" content="https://www.cameronimmigration.com/static/media/thumbnail-img.3746441c010fec9f33b6.png"></meta>
            <meta content="There are 100’s of options for visiting, migrating and remaining in Canada. As Canadian immigration law continues to adjust its rules and regulations, its important to have up to date knowledge. Each matter is unique and must be looked at from its own perspective, allowing us to thoroughly evaluate each matter and seek the best possible route in immigrating or remaining in Canada." />
            <meta property="og:keywords" content="Leagl Services, Canada Visa, Work Visa canada, Study Visa canada, Visitor Visa canada, Super Visa canada, Family Sponsorship canada, Humanitarian & Compassionate, Express Entry canada, PNP canada, LMIA canada, Business Visitors canada, Seasonal Workers canada"/>
            <meta name="keywords" content="Leagl Services, Canada Visa, Work Visa canada, Study Visa canada, Visitor Visa canada, Super Visa canada, Family Sponsorship canada, Humanitarian & Compassionate, Express Entry canada, PNP canada, LMIA canada, Business Visitors canada, Seasonal Workers canada"/>
            <meta content="Cameron Immigration & Legal Services" name="author" />
            <meta name="title" content="About Us | Cameron Immigration & Legal Services"/>
            <link rel="canonical" href="https://www.cameronimmigration.com/" />
            <meta http-equiv='cache-control' content='no-cache'/>
            <meta http-equiv='expires' content='0'/>
            <meta http-equiv='pragma' content='no-cache'/>
            <link rel="icon" href="/cameron-logo.jpg" type="image/png" sizes="16x16"/>
        </Helmet>
        <Header></Header>
        <div className='about-us-page'>
            <div className='page-banner'>
                <div className='container'>
                    <div className='right-sec'>
                        <div className='sec-title'>
                            <span>About Us</span>
                        </div>
                        <div className='sec-title2'>
                            <h2>Guiding you through your immigration journey with experience and transparency</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className='welcome-sec'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-sm-12 col-md-6 col-xl-7 col-xxl-7'>
                            <div className='left-side'>
                                <div className='sec-title2'>
                                    <h2>Welcome!</h2>
                                </div>
                                <div className='sec-text'>
                                    <p>There are 100’s of options for visiting, migrating and remaining in Canada. As Canadian immigration law continues to adjust its rules and regulations, its important to have up to date knowledge. Each matter is unique and must be looked at from its own perspective, allowing us to thoroughly evaluate each matter and seek the best possible route in immigrating or remaining in Canada.</p>
                                    <p>We are conveniently located within the Greater Toronto Area for ease of access to all Immigration hearings with the Immigration Appeal Division, Immigration Refugee Board, CBSA and all other immigration related maters to ensure our clients the comfort and reliance they can depend upon.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-6 col-xl-5 col-xxl-5'>
                            <div className='right-sec'>
                                <img src={require('../images/welcome.png')} alt="Cameron Immigration & Legal Services"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='happyclients-sec'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-sm-12 col-md-6 col-xl-7 col-xxl-7'>
                            <div className='left-side'>
                                <div className='sec-title2'>
                                    <h2>500+</h2>
                                </div>
                                <div className='sec-title3'>
                                    <h2>Happy clients</h2>
                                </div>
                                <div className='sec-text'>
                                    <p>We're proud when our clients are happy. We've helped over 500 individuals, families, and businesses. They trust us because we're open, honest, and dependable. Their kind words and recommendations show how much they value our help in achieving their immigration goals.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-6 col-xl-5 col-xxl-5'>
                            <div className='right-sec'>
                                <img src={require('../images/happy-clients.png')} alt="Cameron Immigration & Legal Services"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='our-journey'>
                <div className='container'>
                    <div className='right-sec'>
                        <div className='sec-title'>
                            <span>Our Journey</span>
                        </div>
                        <div className='sec-title2'>
                            <h2>Our Journey Starts Here</h2>
                        </div>
                        <div className='sec-text'>
                            <p>There are various pathways to visit, relocate, or establish residence in Canada. As the rules and regulations in Canadian immigration continuously evolve, staying current is crucial. We prioritize understanding the distinct circumstances of each case, recognizing that every situation is unique. This personalized approach enables us to determine the optimal avenues for individuals seeking immigration or residency in Canada.</p>
                            <p>Situated in the Greater Toronto Area, our office's strategic location provides easy accessibility for Immigration hearings. We offer comprehensive support for clients navigating Immigration Appeal Division, Immigration Refugee Board, CBSA, and other immigration-related proceedings. Our primary goal is to instill comfort and confidence in our clients, assuring them of our reliable assistance throughout their immigration journey.</p>
                            <p>Cameron Immigration & Legal Services is a Canadian-based firm specializing in facilitating visits, immigration, or family reunification in Canada. Our commitment extends to individuals seeking temporary or permanent stays, as we strive to streamline the process for all our clients.</p>
                            <p>Successful immigration cases thrive on collaborative efforts, where the client plays an integral role. Brianne Cameron, serving as the lead counsel at Cameron Immigration & Legal Services, embodies integrity, offering forthright counsel and fostering trust with clients. Brianne holds licenses as a Paralegal with the Law Society of Ontario, a Registered Canadian Immigration Consultant (RCIC), and also holds positions as a Notary Public and a Commissioner of Affidavits. Her unparalleled background, working alongside esteemed immigration lawyers, distinguishes her expertise. Brianne and team eagerly anticipate guiding you through your journey towards Canada.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <PageBottom></PageBottom>
        <Footer></Footer>
        
    </div>
  )
}
