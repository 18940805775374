import React from 'react';
import ReactDOM from 'react-dom/client';
import "bootstrap/dist/css/bootstrap.min.css";
import './index.css';
import "bootstrap/dist/js/bootstrap.bundle.js";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "odometer/themes/odometer-theme-default.css";
import "jquery/dist/jquery.js";
import App from './App';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import TermsAndPolicies from './pages/TermsAndPolicies';
import WorkerStudyVisitor from './pages/WorkerStudyVisitor';
import PermanentStatus from './pages/PermanentStatus';
import CorporateandBusinessServices from './pages/CorporateandBusinessServices';
import Testimonials from './pages/Testimonials';
import ClientPortal from './pages/ClientPortal';
import FreeAssessment from './pages/FreeAssessment';
import BookConsultation from './pages/BookConsultation';



const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },

  {
    path: "/home",
    element: <App />,
  },

  {
    path: "/about",
    element: <AboutUs />,
  },

  {
    path: "/contact",
    element: <ContactUs />,
  },

  {
    path: "/terms-and-policies",
    element: <TermsAndPolicies />,
  },

  {
    path: "/worker-study-visitor",
    element: <WorkerStudyVisitor />,
  },

  {
    path: "/permanent-status",
    element: <PermanentStatus />,
  },

  {
    path: "/corporate-and-business-services",
    element: <CorporateandBusinessServices />,
  },

  {
    path: "/testimonials",
    element: <Testimonials />,
  },

  {
    path: "/client-portal",
    element: <ClientPortal />,
  },

  {
    path: "/assessment",
    element: <FreeAssessment />,
  },

  {
    path: "/bookappointment",
    element: <BookConsultation />,
  }


]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <App /> */}

    <RouterProvider router={router} />
  </React.StrictMode>
);


reportWebVitals();
