import React from 'react'
import logo from '../cameron-logo.jpg';
import { NavLink, Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

function Header() {
  return (
    
    <nav className="navbar navbar-expand-lg">
    <div className="container">
      <Link className="navbar-brand" to="/home">
        <img src={logo} className='logo' alt="Cameron Immigration"/>
      </Link>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 16 16" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.5 11.5C2.5 11.2239 2.72386 11 3 11H13C13.2761 11 13.5 11.2239 13.5 11.5C13.5 11.7761 13.2761 12 13 12H3C2.72386 12 2.5 11.7761 2.5 11.5Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.5 7.5C2.5 7.22386 2.72386 7 3 7H13C13.2761 7 13.5 7.22386 13.5 7.5C13.5 7.77614 13.2761 8 13 8H3C2.72386 8 2.5 7.77614 2.5 7.5Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.5 3.5C2.5 3.22386 2.72386 3 3 3H13C13.2761 3 13.5 3.22386 13.5 3.5C13.5 3.77614 13.2761 4 13 4H3C2.72386 4 2.5 3.77614 2.5 3.5Z" fill="white"/>
      </svg>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav justify-content-end w-100">
          <li className="nav-item">
            <NavLink className="nav-link" activeClassName="nav-link--active"  aria-current="page" to='/home'>Home</NavLink>
          </li>
          <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Services
            </a>
            <ul className="dropdown-menu">
              <li><HashLink className="dropdown-item" smooth to='/worker-study-visitor#work-visa'>Work Visa</HashLink></li>
              <li><HashLink className="dropdown-item" smooth to='/worker-study-visitor#study-visa'>Study Visa</HashLink></li>
              <li><HashLink className="dropdown-item" smooth to='/worker-study-visitor#visitor-visa'>Visitor Visa</HashLink></li>
              <li><HashLink className="dropdown-item" smooth to='/worker-study-visitor#super-visa'>Super Visa</HashLink></li>
              <li><HashLink className="dropdown-item" smooth to='/permanent-status#family-sponsorship'>Family Sponsorship</HashLink></li>
              <li><HashLink className="dropdown-item" smooth to='/permanent-status#humanitarian-and-compassionate'>Humanitarian & Compassionate</HashLink></li>
              <li><HashLink className="dropdown-item" smooth to='/permanent-status#express-entry'>Express Entry</HashLink></li>
              <li><HashLink className="dropdown-item" smooth to='/permanent-status#PNP'>Provincial Nominee Program (PNP)</HashLink></li>
              <li><HashLink className="dropdown-item" smooth to='/corporate-and-business-services#LMIA'>Labour Market Impact Assessment (LMIA)</HashLink></li>
              <li><HashLink className="dropdown-item" smooth to='/corporate-and-business-services#buisness-visitors'>Business Visitors</HashLink></li>
              <li><HashLink className="dropdown-item" smooth to='/corporate-and-business-services#seasonal-workers'>Seasonal Workers</HashLink></li>
            </ul>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" activeClassName="nav-link--active" to='/testimonials'>Testimonials</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" activeClassName="nav-link--active" to="/assessment">Assessment</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" activeClassName="nav-link--active" to="/client-portal">Client Portal</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" activeClassName="nav-link--active" to='/about'>About Us</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" activeClassName="nav-link--active" to='/contact'>Contact Us</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link btn book-consultant-btn" to="/bookappointment">Book Consultation</NavLink>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  )
}

export default Header;