import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import PageBottom from '../components/PageBottom'
import { Helmet } from 'react-helmet'
import ReactGA from "react-ga4"
import { useEffect } from 'react'

export default function Testimonials() {

    ReactGA.initialize("G-RQ96KPQZ6W");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/testimonials", title: "Our Testimonials" });

    useEffect(() => {
        document.title = 'Our Testimonials | Cameron Immigration & Legal Services';
      }, []);

  return (
    <div>
        <Helmet>
            <title>Our Testimonials | Cameron Immigration & Legal Services</title>
            <meta content="width=device-width, initial-scale=1.0" name="viewport" />
            <meta property="og:title" content="Our Testimonials | Cameron Immigration & Legal Services" />
            <meta property="og:site_name" content="Cameron Immigration & Legal Services"/>
            <meta property="og:url" content="https://www.cameronimmigration.com/"/>
            <meta property="og:description" content="There are 100’s of options for visiting, migrating and remaining in Canada. As Canadian immigration law continues to adjust its rules and regulations, its important to have up to date knowledge. Each matter is unique and must be looked at from its own perspective, allowing us to thoroughly evaluate each matter and seek the best possible route in immigrating or remaining in Canada."/>
            <meta property="og:type" content="website"/>
            <meta property="og:image" content="https://www.cameronimmigration.com/static/media/thumbnail-img.3746441c010fec9f33b6.png"></meta>
            <meta content="There are 100’s of options for visiting, migrating and remaining in Canada. As Canadian immigration law continues to adjust its rules and regulations, its important to have up to date knowledge. Each matter is unique and must be looked at from its own perspective, allowing us to thoroughly evaluate each matter and seek the best possible route in immigrating or remaining in Canada." />
            <meta property="og:keywords" content="Leagl Services, Canada Visa, Work Visa canada, Study Visa canada, Visitor Visa canada, Super Visa canada, Family Sponsorship canada, Humanitarian & Compassionate, Express Entry canada, PNP canada, LMIA canada, Business Visitors canada, Seasonal Workers canada"/>
            <meta name="keywords" content="Leagl Services, Canada Visa, Work Visa canada, Study Visa canada, Visitor Visa canada, Super Visa canada, Family Sponsorship canada, Humanitarian & Compassionate, Express Entry canada, PNP canada, LMIA canada, Business Visitors canada, Seasonal Workers canada"/>
            <meta content="Cameron Immigration & Legal Services" name="author" />
            <meta name="title" content="Our Testimonials | Cameron Immigration & Legal Services"/>
            <link rel="canonical" href="https://www.cameronimmigration.com/" />
            <meta http-equiv='cache-control' content='no-cache'/>
            <meta http-equiv='expires' content='0'/>
            <meta http-equiv='pragma' content='no-cache'/>
            <link rel="icon" href="/cameron-logo.jpg" type="image/png" sizes="16x16"/>
            <script defer async src='https://cdn.trustindex.io/loader.js'></script>

          
        </Helmet>
        <Header></Header>

        <div className='testimonials-page'>
            <div className='page-banner'>
                <div className='container'>
                    <div className='right-sec'>
                        <div className='sec-title'>
                            <span>Our Testimonials</span>
                        </div>
                        <div className='sec-title2'>
                            <h2>What Our Clients Think of Us</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className='all-testimonials'>
                <div className='container'>
                   
                    <div className='w-100' src='https://cdn.trustindex.io/loader.js?42dc262236c8381c5e068be42be'></div>
                </div>
            </div>
        </div>

        <PageBottom></PageBottom>
        <Footer></Footer>
        
    </div>
  )
}
