import React, { Component } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import PageBottom from '../components/PageBottom'
import { Helmet } from 'react-helmet'
import { HashLink as Link } from 'react-router-hash-link'
import ReactGA from "react-ga4"
import { useEffect } from 'react'

export default function CorporateandBusinessServices(){
    

    ReactGA.initialize("G-RQ96KPQZ6W");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/corporate-and-business-services", title: "Corporate And Business Services" });

    useEffect(() => {
        document.title = 'Corporate and Business Services | Cameron Immigration & Legal Services';
      }, []);

    return (
      <div>
        <Helmet>
            <title>Corporate and Business Services | Cameron Immigration & Legal Services</title>
            <meta content="width=device-width, initial-scale=1.0" name="viewport" />
            <meta property="og:title" content="Corporate and Business Services | Cameron Immigration & Legal Services" />
            <meta property="og:site_name" content="Cameron Immigration & Legal Services"/>
            <meta property="og:url" content="https://www.cameronimmigration.com/"/>
            <meta property="og:description" content="There are 100’s of options for visiting, migrating and remaining in Canada. As Canadian immigration law continues to adjust its rules and regulations, its important to have up to date knowledge. Each matter is unique and must be looked at from its own perspective, allowing us to thoroughly evaluate each matter and seek the best possible route in immigrating or remaining in Canada."/>
            <meta property="og:type" content="website"/>
            <meta property="og:image" content="https://www.cameronimmigration.com/static/media/thumbnail-img.3746441c010fec9f33b6.png"></meta>
            <meta content="There are 100’s of options for visiting, migrating and remaining in Canada. As Canadian immigration law continues to adjust its rules and regulations, its important to have up to date knowledge. Each matter is unique and must be looked at from its own perspective, allowing us to thoroughly evaluate each matter and seek the best possible route in immigrating or remaining in Canada." />
            <meta property="og:keywords" content="Leagl Services, Canada Visa, Work Visa canada, Study Visa canada, Visitor Visa canada, Super Visa canada, Family Sponsorship canada, Humanitarian & Compassionate, Express Entry canada, PNP canada, LMIA canada, Business Visitors canada, Seasonal Workers canada"/>
            <meta name="keywords" content="Leagl Services, Canada Visa, Work Visa canada, Study Visa canada, Visitor Visa canada, Super Visa canada, Family Sponsorship canada, Humanitarian & Compassionate, Express Entry canada, PNP canada, LMIA canada, Business Visitors canada, Seasonal Workers canada"/>
            <meta content="Cameron Immigration & Legal Services" name="author" />
            <meta name="title" content="Corporate and Business Services | Cameron Immigration & Legal Services"/>
            <link rel="canonical" href="https://www.cameronimmigration.com/" />
            <meta http-equiv='cache-control' content='no-cache'/>
            <meta http-equiv='expires' content='0'/>
            <meta http-equiv='pragma' content='no-cache'/>
            <link rel="icon" href="/cameron-logo.jpg" type="image/png" sizes="16x16"/>

        </Helmet>
        <Header></Header>
        <div className='corporate-services-page'>
            <div className='page-banner'>
                <div className='container'>
                    <div className='right-sec'>
                        <div className='sec-title'>
                            <span>Corporate and Business Services</span>
                        </div>
                        <div className='sec-title2'>
                            <h2>Streamlined Immigration Solutions <br></br>for Canadian businesses </h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className='all-services'>
                <div className='container'>
                   
                    <div className='service-details' id='LMIA'>
                        <div className='row'>
                            <div className='col-12 col-sm-12 col-md-7 col-xl-8 col-xxl-8'>
                                <div className='left-side'>
                                    <div className='sec-title2'>
                                        <h2>Labour Market Impact Assessment (LMIA)</h2>
                                    </div>
                                    <div className='sec-text'>
                                        <p>
                                        The Labor Market Impact Assessment (LMIA) process in Canada involves employers applying for permission to hire foreign workers for jobs where no Canadian workers are available. Employers need to demonstrate efforts to hire locally before seeking foreign workers. The process assesses the impact of hiring a foreign worker on the Canadian job market. If approved, the employer receives an LMIA, allowing them to hire a specific foreign worker for a specific job. This assessment ensures that hiring foreign workers won't negatively affect Canadian job opportunities.
                                        </p>
                                        
                                    </div>
                                    <div className='basic-eligibility'>
                                        <h3>Basic eligibility requirements:</h3>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>Advertise the job for at least four weeks before offering it to a foreign worker.</p>
                                        </div>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>Be a legitimate business providing goods or services in Canada.</p>
                                        </div>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>Show a genuine need for foreign workers in your organization.</p>
                                        </div>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>Have no unresolved issues related to previous hires of foreign workers or employment regulations.</p>
                                        </div>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>Use at least two recruitment channels, along with the Canadian Job Bank’s website, when hiring foreign workers.</p>
                                        </div>
                                    </div>
                                    <div className='book-button'>
                                        <Link smooth to='/bookappointment#top' className=''>Book Consultation <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 23 18" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9C0 8.28992 0.587626 7.71428 1.3125 7.71428L16.5188 7.71428L10.8844 2.19485C10.3719 1.69275 10.3719 0.878677 10.8844 0.376575C11.397 -0.125528 12.228 -0.125528 12.7406 0.376575L20.6156 8.09086C21.1281 8.59296 21.1281 9.40703 20.6156 9.90914L12.7406 17.6234C12.228 18.1255 11.397 18.1255 10.8844 17.6234C10.3719 17.1213 10.3719 16.3072 10.8844 15.8051L16.5188 10.2857H1.3125C0.587626 10.2857 0 9.71008 0 9Z" fill="#192451"/>
                                    </svg></Link>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-sm-12 col-md-5 col-xl-4 col-xxl-4'>
                                <img src={require('../images/lmia.png')} alt='Labour Market Impact Assessment (LMIA)'/>
                            </div>
                        </div>
                    </div>
                    <div className='service-details' id='buisness-visitors'>
                        <div className='row flex-custome-column-reverse'>
                            <div className='col-12 col-sm-12 col-md-5 col-xl-4 col-xxl-4'>
                                <img src={require('../images/buiness-visitor.png')} alt='Business Visitors'/>
                            </div>
                            <div className='col-12 col-sm-12 col-md-7 col-xl-8 col-xxl-8'>
                                <div className='left-side'>
                                    <div className='sec-title2'>
                                        <h2>Business Visitors</h2>
                                    </div>
                                    <div className='sec-text'>
                                        <p>
                                        A business visa to Canada allows individuals to visit the country for business-related activities, such as meetings, conferences, or exploring potential business opportunities. It's designed for those who aren't planning to enter the Canadian labor market but need to engage in business-related activities temporarily. This visa enables individuals to conduct business, network, negotiate contracts, or attend trade shows while visiting Canada.
                                        </p>
                                      
                                    </div>
                                    <div className='basic-eligibility'>
                                        <h3>Basic eligibility requirements:</h3>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>They usually stay in Canada for a short time, not exceeding six months.</p>
                                        </div>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>They engage in international business activities without joining the Canadian job market.</p>
                                        </div>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>Reasons for their visit can include exploring business expansion, making investments, or strengthening business connections.</p>
                                        </div>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>Business visitors are allowed a short stay, up to a maximum of six months</p>
                                        </div>
                                     
                                    </div>
                                    <div className='book-button'>
                                        <Link smooth to='/bookappointment#top' className=''>Book Consultation <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 23 18" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9C0 8.28992 0.587626 7.71428 1.3125 7.71428L16.5188 7.71428L10.8844 2.19485C10.3719 1.69275 10.3719 0.878677 10.8844 0.376575C11.397 -0.125528 12.228 -0.125528 12.7406 0.376575L20.6156 8.09086C21.1281 8.59296 21.1281 9.40703 20.6156 9.90914L12.7406 17.6234C12.228 18.1255 11.397 18.1255 10.8844 17.6234C10.3719 17.1213 10.3719 16.3072 10.8844 15.8051L16.5188 10.2857H1.3125C0.587626 10.2857 0 9.71008 0 9Z" fill="#192451"/>
                                    </svg></Link>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className='service-details' id='seasonal-workers'>
                        <div className='row'>
                            <div className='col-12 col-sm-12 col-md-7 col-xl-8 col-xxl-8'>
                                <div className='left-side'>
                                    <div className='sec-title2'>
                                        <h2>Seasonal Workers</h2>
                                    </div>
                                    <div className='sec-text'>
                                        <p>
                                        The Seasonal Agricultural Worker Program (SAWP) in Canada allows employers in the agriculture sector to hire temporary foreign workers for specific seasonal jobs, mainly in planting, cultivating, and harvesting crops. It's designed to address labor shortages during peak agricultural seasons when additional help is needed. Workers hired under SAWP are employed temporarily and typically return to their home countries after the season ends. This program ensures that Canadian farmers have access to the labor they need for specific periods while offering temporary employment opportunities to foreign workers.
                                        </p>
                                    </div>
                                    <div className='basic-eligibility'>
                                        <h3>Basic eligibility requirements:</h3>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>Have Canadian work experience in specific industries or jobs.</p>
                                        </div>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>Need a real job offer from a Canadian employer in the agri-food sector.</p>
                                        </div>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>Meet or exceed language proficiency requirements.</p>
                                        </div>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>Meet educational criteria specified for the program.</p>
                                        </div>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>Combining hours from multiple jobs to reach 30 hours per week for 12 months.</p>
                                        </div>
                                        <div className='key-point1'>
                                            <div>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                            </svg>
                                            </div>
                                            <p>Show enough money to settle in Canada, if needed.</p>
                                        </div>
                                        <div className='key-point1'>
                                            <div>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                            </svg>
                                            </div>
                                            <p>If already in Canada, maintain temporary resident status.</p>
                                        </div>
                                    </div>
                                    <div className='book-button'>
                                        <Link smooth to='/bookappointment#top' className=''>Book Consultation <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 23 18" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9C0 8.28992 0.587626 7.71428 1.3125 7.71428L16.5188 7.71428L10.8844 2.19485C10.3719 1.69275 10.3719 0.878677 10.8844 0.376575C11.397 -0.125528 12.228 -0.125528 12.7406 0.376575L20.6156 8.09086C21.1281 8.59296 21.1281 9.40703 20.6156 9.90914L12.7406 17.6234C12.228 18.1255 11.397 18.1255 10.8844 17.6234C10.3719 17.1213 10.3719 16.3072 10.8844 15.8051L16.5188 10.2857H1.3125C0.587626 10.2857 0 9.71008 0 9Z" fill="#192451"/>
                                    </svg></Link>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-sm-12 col-md-5 col-xl-4 col-xxl-4'>
                                <img src={require('../images/seasonal-worker.png')} alt='Seasonal Workers'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <PageBottom></PageBottom>
        <Footer></Footer>
        
      </div>
    )
}
