import React from 'react'
import Slider from "react-slick";

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={'nextarrow'}
        style={{ ...style, }}
        onClick={onClick}
      />
    );
  }
  
  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={'prevarrow'}
        style={{ ...style, }}
        onClick={onClick}
      />
    );
  }

function OurTestiSlider() {
    
    const TestiSlider = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        autoplay: false,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
            }
            },
            {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
            }
            },
            {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
            }
        ]
      };
  return (
    <div className='our-testi-slider1'>
        <Slider {...TestiSlider}>
            {TestimonialsData.map((d) => (
                <div className='sliderCard'>
                    <div className='testi-card'>
                      <div className='testi-visa-type'>
                        <span>{d.visatype}</span>
                      </div>
                      <div className='testi-cuatomer-name'>
                        <h2>{d.customername}</h2> 
                        <span>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.51538 19.3034C4.03233 19.5517 3.48537 19.1178 3.58262 18.5634L4.61978 12.6516L0.216861 8.45578C-0.194614 8.06366 0.0183857 7.34671 0.569622 7.2684L6.69272 6.39856L9.42287 0.990381C9.6688 0.503206 10.335 0.503206 10.5809 0.990381L13.3111 6.39856L19.4342 7.2684C19.9854 7.34671 20.1984 8.06366 19.787 8.45578L15.384 12.6516L16.4212 18.5634C16.5184 19.1178 15.9715 19.5517 15.4884 19.3034L10.0019 16.4835L4.51538 19.3034Z" fill="#FDCC0D"/>
                            </svg>
                        </span>
                        <span>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.51538 19.3034C4.03233 19.5517 3.48537 19.1178 3.58262 18.5634L4.61978 12.6516L0.216861 8.45578C-0.194614 8.06366 0.0183857 7.34671 0.569622 7.2684L6.69272 6.39856L9.42287 0.990381C9.6688 0.503206 10.335 0.503206 10.5809 0.990381L13.3111 6.39856L19.4342 7.2684C19.9854 7.34671 20.1984 8.06366 19.787 8.45578L15.384 12.6516L16.4212 18.5634C16.5184 19.1178 15.9715 19.5517 15.4884 19.3034L10.0019 16.4835L4.51538 19.3034Z" fill="#FDCC0D"/>
                            </svg>
                        </span>
                        <span>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.51538 19.3034C4.03233 19.5517 3.48537 19.1178 3.58262 18.5634L4.61978 12.6516L0.216861 8.45578C-0.194614 8.06366 0.0183857 7.34671 0.569622 7.2684L6.69272 6.39856L9.42287 0.990381C9.6688 0.503206 10.335 0.503206 10.5809 0.990381L13.3111 6.39856L19.4342 7.2684C19.9854 7.34671 20.1984 8.06366 19.787 8.45578L15.384 12.6516L16.4212 18.5634C16.5184 19.1178 15.9715 19.5517 15.4884 19.3034L10.0019 16.4835L4.51538 19.3034Z" fill="#FDCC0D"/>
                            </svg>
                        </span>
                        <span>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.51538 19.3034C4.03233 19.5517 3.48537 19.1178 3.58262 18.5634L4.61978 12.6516L0.216861 8.45578C-0.194614 8.06366 0.0183857 7.34671 0.569622 7.2684L6.69272 6.39856L9.42287 0.990381C9.6688 0.503206 10.335 0.503206 10.5809 0.990381L13.3111 6.39856L19.4342 7.2684C19.9854 7.34671 20.1984 8.06366 19.787 8.45578L15.384 12.6516L16.4212 18.5634C16.5184 19.1178 15.9715 19.5517 15.4884 19.3034L10.0019 16.4835L4.51538 19.3034Z" fill="#FDCC0D"/>
                            </svg>
                        </span>
                        <span>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.51538 19.3034C4.03233 19.5517 3.48537 19.1178 3.58262 18.5634L4.61978 12.6516L0.216861 8.45578C-0.194614 8.06366 0.0183857 7.34671 0.569622 7.2684L6.69272 6.39856L9.42287 0.990381C9.6688 0.503206 10.335 0.503206 10.5809 0.990381L13.3111 6.39856L19.4342 7.2684C19.9854 7.34671 20.1984 8.06366 19.787 8.45578L15.384 12.6516L16.4212 18.5634C16.5184 19.1178 15.9715 19.5517 15.4884 19.3034L10.0019 16.4835L4.51538 19.3034Z" fill="#FDCC0D"/>
                            </svg>
                        </span>
                      </div>
                      <div className='testi-content'>
                        <p>{d.testimonials}</p>
                      </div>
                  </div>
                </div>

            ))}

        </Slider>
    </div>
  )
}

const TestimonialsData = 
[
    {
        visatype: 'Work permit',
        customername: 'Mogaji Clement',
        testimonials: 'After 14th months of my wife and Daughter traveled to Canada, the first visa that I applied for was in a wrong category and it was rejected, felt lonely and shattered,I dont know what next to do, then someone introduced me to Cameron Immigration, she asked me questions, which I explained,then she applied for a open work permit for me, she handled everything professionally with all my disturbance , alot of messages from me which she always answers and explain to me each time , after 3months plus of waiting, my visa got approved, made my dream of uniting with my family come through, my dear daughter that have not seen for a year plus, will be in my arms soon and I will be with my beautiful wife soonest , all I have to say is Thank you to Cameron Immigration for a Job well done, thank you so much for putting smiles in our family.'
    },
    {
        visatype: 'Study permit',
        customername: 'Ifeoma Fausta',
        testimonials: 'I really thank God for bringing cameronimmigration and legal services to my path, when I thought all hope were lost ,God used Cameron immigration and legal services to restore my visa ,within 3 weeks I got my visa now Iam in Canada.she is trustworthy, God bless you.Thank you so much.'
    },
    {
        visatype: 'Family Sponsorship',
        customername: 'Shereen Cresser',
        testimonials: 'I cannot say enough about Mrs Bri Cameron. This woman put her all into bringing my husband back home after almost 3 years apart. She is clear and concise and knows what she is talking about. She listens and has patience and completely understands how hard it is to be separated from your loved ones. If you want some one who is detailed oriented and very thorough she is your girl. She doesnt just handle your file but she walks you through it step by step you will not feel alone.Thats what i appreciated the most. If i called her i never felt rushed i felt like i was being educated and prepared for whats to come.  She turned my terrible application from a previous terrible representative into something that could not be questioned and for that i will forever be grateful. SHE IS AMAZING!!!!'
    },

    {
      visatype: 'Canadian Citizenship',
      customername: 'Tammy Meeks',
      testimonials: 'If you want a trustworthy, dedicated, professional who is empathetic and passionate about the wellbeing of others, CHOOSE Cameron Immigration Services. She is the reason why my whole family and extended family are now, not only permanent Residents of Canada, but Citizens. Cameron Immigration Service, was fast and efficient and treated our immigration concerns holistically. Brianne looked at every angle of our problem and took on the challenge every other lawyer refused and was successful doing it! She will look at every part of your issues to find the ideal solution to get you to Canada and also remain in Canada legally. This Service was honest about the expectations and outcome of our case and we are indeed grateful for that. After several negative experiences with other lawyers we can finally say this is by far one of the best immigration Consultation Service I have encountered and I will continue to refer others to them. Thank you so much for your help. We have finally made it!  Canada! 🇨🇦'
  },
    
    
  
   
];

export default OurTestiSlider;