import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import PageBottom from '../components/PageBottom'
import { Helmet } from 'react-helmet'
import { HashLink as Link } from 'react-router-hash-link'
import ReactGA from "react-ga4"
import { useEffect } from 'react'

export default function WorkerStudyVisitor() {

    ReactGA.initialize("G-RQ96KPQZ6W");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/worker-study-visitor", title: "Worker / Study / Visitor" });

    useEffect(() => {
        document.title = 'Worker / Study / Visitor | Cameron Immigration & Legal Services';
      }, []);

  return (
    <div>
        <Helmet>
            <title>Worker / Study / Visitor | Cameron Immigration & Legal Services</title>
            <meta content="width=device-width, initial-scale=1.0" name="viewport" />
            <meta property="og:title" content="Worker / Study / Visitor | Cameron Immigration & Legal Services" />
            <meta property="og:site_name" content="Cameron Immigration & Legal Services"/>
            <meta property="og:url" content="https://www.cameronimmigration.com/"/>
            <meta property="og:description" content="There are 100’s of options for visiting, migrating and remaining in Canada. As Canadian immigration law continues to adjust its rules and regulations, its important to have up to date knowledge. Each matter is unique and must be looked at from its own perspective, allowing us to thoroughly evaluate each matter and seek the best possible route in immigrating or remaining in Canada."/>
            <meta property="og:type" content="website"/>
            <meta property="og:image" content="https://www.cameronimmigration.com/static/media/thumbnail-img.3746441c010fec9f33b6.png"></meta>
            <meta content="There are 100’s of options for visiting, migrating and remaining in Canada. As Canadian immigration law continues to adjust its rules and regulations, its important to have up to date knowledge. Each matter is unique and must be looked at from its own perspective, allowing us to thoroughly evaluate each matter and seek the best possible route in immigrating or remaining in Canada." />
            <meta property="og:keywords" content="Leagl Services, Canada Visa, Work Visa canada, Study Visa canada, Visitor Visa canada, Super Visa canada, Family Sponsorship canada, Humanitarian & Compassionate, Express Entry canada, PNP canada, LMIA canada, Business Visitors canada, Seasonal Workers canada"/>
            <meta name="keywords" content="Leagl Services, Canada Visa, Work Visa canada, Study Visa canada, Visitor Visa canada, Super Visa canada, Family Sponsorship canada, Humanitarian & Compassionate, Express Entry canada, PNP canada, LMIA canada, Business Visitors canada, Seasonal Workers canada"/>
            <meta content="Cameron Immigration & Legal Services" name="author" />
            <meta name="title" content="Worker / Study / Visitor | Cameron Immigration & Legal Services"/>
            <link rel="canonical" href="https://www.cameronimmigration.com/" />
            <meta http-equiv='cache-control' content='no-cache'/>
            <meta http-equiv='expires' content='0'/>
            <meta http-equiv='pragma' content='no-cache'/>
            <link rel="icon" href="/cameron-logo.jpg" type="image/png" sizes="16x16"/>

         
        </Helmet>
        <Header></Header>
        <div className='service-page'>
            <div className='page-banner'>
                <div className='container'>
                    <div className='right-sec'>
                        <div className='sec-title'>
                            <span>Worker / Study / Visitor</span>
                        </div>
                        <div className='sec-title2'>
                            <h2>Empowering Your Immigration <br></br>and Visa Success</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className='all-services'>
                <div className='container'>
                    <p className='top-text'>
                    Temporary status in Canada refers to a situation where someone is allowed to stay in the country for a limited period. This could include visitors, students, or workers who have authorization to be in Canada for a specific purpose and duration, such as tourism, studying, or working on a temporary basis. Temporary status doesn't grant permanent residency or citizenship but allows individuals to stay for the duration permitted by their visa, permit, or authorization.
                    </p>
                    <div className='service-details' id="work-visa">
                        <div className='row '>
                            <div className='col-12 col-sm-12 col-md-7 col-xl-8 col-xxl-8'>
                                <div className='left-side'>
                                    <div className='sec-title2' >
                                        <h2>Work Visa</h2>
                                    </div>
                                    <div className='sec-text'>
                                        <p>
                                        Getting a working visa or permit in Canada can be a bit complex because they're split into different categories. Usually, people who want to work in Canada need a Canadian company to hire them and assist with their working visa application. Only a few people can apply for an Open permit, which lets them work for any employer they want. Work permits come with certain rules, and some jobs in Canada might need a medical exam. We'd be happy to chat with you and figure out what kind of working visa or permit you qualify for.
                                        </p>
                                    </div>
                                    <div className='basic-eligibility'>
                                        <h3>Basic eligibility requirements:</h3>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>Show that you plan to leave Canada when your work permit ends.</p>
                                        </div>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>Prove you have enough money to support yourself and your family while in Canada and for your trip back home.</p>
                                        </div>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>Ensure you don’t pose a security risk to Canada.</p>
                                        </div>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>Give any extra documents the officer asks for to prove you can enter Canada.</p>
                                        </div>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>Have a clean record without any criminal activity. You might need a police clearance certificate.</p>
                                        </div>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>Be healthy, and if necessary, undergo a medical exam.</p>
                                        </div>
                                    </div>
                                    <div className='book-button'>
                                        <Link smooth to='/bookappointment#top' className=''>Book Consultation <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 23 18" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9C0 8.28992 0.587626 7.71428 1.3125 7.71428L16.5188 7.71428L10.8844 2.19485C10.3719 1.69275 10.3719 0.878677 10.8844 0.376575C11.397 -0.125528 12.228 -0.125528 12.7406 0.376575L20.6156 8.09086C21.1281 8.59296 21.1281 9.40703 20.6156 9.90914L12.7406 17.6234C12.228 18.1255 11.397 18.1255 10.8844 17.6234C10.3719 17.1213 10.3719 16.3072 10.8844 15.8051L16.5188 10.2857H1.3125C0.587626 10.2857 0 9.71008 0 9Z" fill="#192451"/>
                                    </svg></Link>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-sm-12 col-md-5 col-xl-4 col-xxl-4'>
                                <img src={require('../images/work-visa.png')} alt='Work Visa'/>
                            </div>
                        </div>
                    </div>
                    <div className='service-details' id='study-visa'>
                        <div className='row flex-custome-column-reverse' >
                            <div className='col-12 col-sm-12 col-md-5 col-xl-4 col-xxl-4'>
                                <img src={require('../images/study-visa.png')} alt='Study Visa'/>
                            </div>
                            <div className='col-12 col-sm-12 col-md-7 col-xl-8 col-xxl-8'>
                                <div className='left-side'>
                                    <div className='sec-title2'>
                                        <h2>Study Visa</h2>
                                    </div>
                                    <div className='sec-text'>
                                        <p>
                                        A study visa or permit is like a ticket for people who want to study in Canada as international students. To get this, there are two main ways: one is called the Student Direct Stream (SDS), and the other is the Non-SDS way. It's important to know that SDS is only for citizens from 14 specific countries.
                                        </p>
                                        <p>
                                        Going to study in Canada is really exciting, but it can also be tricky and uncertain. That's why our team is here to help you with the application. We want to make the visa application less stressful for you by guiding you through the whole process step by step.
                                        </p>
                                    </div>
                                    <div className='basic-eligibility'>
                                        <h3>Basic eligibility requirements:</h3>
                                        <div className='key-point1'>
                                        <div>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                            </svg>
                                        </div>
                                        <p>Gather important documents like school records, work experience proof, financial statements, and other relevant papers.</p>
                                        </div>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>Get an acceptance letter from a recognized Designated Learning Institution (DLI) in Canada.</p>
                                        </div>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>Show you have enough money to pay for your school fees, living expenses, travel back home, and other costs while you're in Canada.</p>
                                        </div>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>Provide a police certificate if it's needed.</p>
                                        </div>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>Prove that you plan to leave Canada after finishing your studies.</p>
                                        </div>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>Be healthy, and if necessary, undergo a medical exam.</p>
                                        </div>
                                    </div>
                                    <div className='book-button'>
                                        <Link smooth to='/bookappointment#top' className=''>Book Consultation <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 23 18" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9C0 8.28992 0.587626 7.71428 1.3125 7.71428L16.5188 7.71428L10.8844 2.19485C10.3719 1.69275 10.3719 0.878677 10.8844 0.376575C11.397 -0.125528 12.228 -0.125528 12.7406 0.376575L20.6156 8.09086C21.1281 8.59296 21.1281 9.40703 20.6156 9.90914L12.7406 17.6234C12.228 18.1255 11.397 18.1255 10.8844 17.6234C10.3719 17.1213 10.3719 16.3072 10.8844 15.8051L16.5188 10.2857H1.3125C0.587626 10.2857 0 9.71008 0 9Z" fill="#192451"/>
                                    </svg></Link>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className='service-details' id="visitor-visa">
                        <div className='row '>
                            <div className='col-12 col-sm-12 col-md-7 col-xl-8 col-xxl-8'>
                                <div className='left-side'>
                                    <div className='sec-title2'>
                                        <h2>Visitor Visa</h2>
                                    </div>
                                    <div className='sec-text'>
                                        <p>
                                        If you're looking to visit Canada for tourism, medical reasons, or to visit family and friends, you might need a Visitor Visa, also called a Temporary Resident Visa. This visa is stamped into your passport and allows you to travel to Canada for a short time.
                                        </p>
                                    </div>
                                    <div className='basic-eligibility'>
                                        <h3>Basic eligibility requirements:</h3>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>Have a valid travel document like a passport.</p>
                                        </div>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>Be healthy and provide any needed medical reports.</p>
                                        </div>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>Don't have any criminal or immigration-related convictions.</p>
                                        </div>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>Show you have enough money to cover your stay. The amount depends on how long you'll be there and where you'll stay (like a hotel or with friends/family).</p>
                                        </div>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>Have a clean record without any criminal activity. You might need a police clearance certificate.</p>
                                        </div>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>Be healthy, and if necessary, undergo a medical exam.</p>
                                        </div>
                                    </div>
                                    <div className='book-button'>
                                        <Link smooth to='/bookappointment#top' className=''>Book Consultation <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 23 18" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9C0 8.28992 0.587626 7.71428 1.3125 7.71428L16.5188 7.71428L10.8844 2.19485C10.3719 1.69275 10.3719 0.878677 10.8844 0.376575C11.397 -0.125528 12.228 -0.125528 12.7406 0.376575L20.6156 8.09086C21.1281 8.59296 21.1281 9.40703 20.6156 9.90914L12.7406 17.6234C12.228 18.1255 11.397 18.1255 10.8844 17.6234C10.3719 17.1213 10.3719 16.3072 10.8844 15.8051L16.5188 10.2857H1.3125C0.587626 10.2857 0 9.71008 0 9Z" fill="#192451"/>
                                    </svg></Link>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-sm-12 col-md-5 col-xl-4 col-xxl-4'>
                                <img src={require('../images/visitor-visa.png')} alt='Study Visa'/>
                            </div>
                        </div>
                    </div>
                    <div className='service-details' id="super-visa">
                        <div className='row flex-custome-column-reverse'>
                            <div className='col-12 col-sm-12 col-md-5 col-xl-4 col-xxl-4'>
                                <img src={require('../images/super-visa.png')} alt='Super Visa'/>
                            </div>
                            <div className='col-12 col-sm-12 col-md-7 col-xl-8 col-xxl-8'>
                                <div className='left-side'>
                                    <div className='sec-title2'>
                                        <h2>Super Visa</h2>
                                    </div>
                                    <div className='sec-text'>
                                        <p>
                                        A Super Visa is a unique kind of visa designed for parents and grandparents of Canadian citizens or permanent residents. It lets them visit their children or grandchildren in Canada for a longer time. With a Super Visa, you can stay in Canada for up to 5 years each time you visit, and you can come and go multiple times within a span of up to 10 years. To be eligible for a Super Visa, there are some specific rules to meet, like having medical insurance.
                                        </p>
                                        
                                    </div>
                                    <div className='basic-eligibility'>
                                        <h3>Basic eligibility requirements:</h3>
                                        <div className='key-point1'>
                                        <div>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                            </svg>
                                        </div>
                                        <p>You must be the parent or grandparent of a Canadian citizen or permanent resident.</p>
                                        </div>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>Get an invitation letter from your child or grandchild in Canada. (This letter should promise financial support during your visit and include details about their household).</p>
                                        </div>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>The inviting person needs to provide a copy of their Canadian citizenship or permanent resident document.</p>
                                        </div>
                                        <div className='key-point1'>
                                            <div>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                            </svg>
                                            </div>
                                            <p>Get medical insurance from a Canadian or approved foreign insurance company. It should:</p>
                                            
                                        </div>
                                        <ul>
                                            <li>Be fully paid or paid in instalments with a deposit.</li>
                                            <li>Last for at least 1 year from your planned entry date.</li>
                                            <li>Cover health care, hospitalization, and repatriation.</li>
                                            <li>Offer at least $100,000 CAD in emergency coverage.</li>
                                        </ul>
                                        <div className='key-point1'>
                                        <div>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.712 6.21209C14.0782 5.84597 14.6717 5.84597 15.0379 6.21209C15.3995 6.57371 15.4039 7.15727 15.0512 7.52434L10.0609 13.7622C10.0537 13.7712 10.046 13.7798 10.0379 13.7879C9.67175 14.154 9.07816 14.154 8.71204 13.7879L5.40398 10.4799C5.03787 10.1137 5.03787 9.52015 5.40398 9.15403C5.7701 8.78791 6.36369 8.78791 6.72981 9.15403L9.34671 11.7709L13.6872 6.24013C13.6949 6.23029 13.7032 6.22093 13.712 6.21209Z" fill="#192451"/>
                                        </svg>
                                        </div>
                                        <p>Make sure your private health insurance is valid for each time you enter Canada.</p>
                                            
                                        </div>
                                        
                                        
                                    </div>
                                    <div className='book-button'>
                                        <Link smooth to='/bookappointment#top' className=''>Book Consultation <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 23 18" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9C0 8.28992 0.587626 7.71428 1.3125 7.71428L16.5188 7.71428L10.8844 2.19485C10.3719 1.69275 10.3719 0.878677 10.8844 0.376575C11.397 -0.125528 12.228 -0.125528 12.7406 0.376575L20.6156 8.09086C21.1281 8.59296 21.1281 9.40703 20.6156 9.90914L12.7406 17.6234C12.228 18.1255 11.397 18.1255 10.8844 17.6234C10.3719 17.1213 10.3719 16.3072 10.8844 15.8051L16.5188 10.2857H1.3125C0.587626 10.2857 0 9.71008 0 9Z" fill="#192451"/>
                                    </svg></Link>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <PageBottom></PageBottom>
        <Footer></Footer>
        
    </div>
  )
}
